import ViewTechnologicalShowcase from "./View";
import EditTechnologicalShowCase from "./Edit";
import iconShowCase from "../../assets/icons/iconShowCase.svg"

const config =  {
    id: "TechnologicalShowcase",
    title: "Vitrine Tecnológica",
    group: "media",
    view: ViewTechnologicalShowcase,
    edit: EditTechnologicalShowCase,
    icon: iconShowCase,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1
}

export default config;