/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import BarraGov from './BarraGov/BarraGov';
import './styles.css';

import { Logo, SearchWidget } from '@plone/volto/components';

import { IconSearch } from '../../../../addons/utfpr_components/src/assets/icons/buttons/IconSearch';
import { HeaderMobile } from './Mobile/HeaderMobile';
import { HeaderDesktop } from './Desktop/HeaderDesktop';
import { CustomizeMenuDesktop } from './CustomizeMenu';

const Header = (props) => {
  const [searchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    if (!window.googleTranslateElementInit) {
      window.googleTranslateElementInit = function () {
        new google.translate.TranslateElement(
          { pageLanguage: 'pt', includedLanguages: 'en,pt,fr,es' },
          'google_translate_element'
        );
      };

      const script = document.createElement('script');
      script.src =
        'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div className="flex flex-col">
      <div id="google_translate_element" className="hidden" />
      <BarraGov />
      <div
        className={`${searchOpen && 'bg-[#FFBE00]'} transition duration-300  `}
      >
        <CustomizeMenuDesktop token={props.token} />
        <div className="w-full container mx-auto pt-14 px-4 transition-[0.5s]">
          <div className="w-full flex justify-between items-center">
            <a title="Início" href="/" className="w-fit hidden sm:block">
              <Logo searchOpen={searchOpen} />
            </a>
            <a title="Início" href="/" className="w-fit block sm:hidden">
              <Logo searchOpen={searchOpen} withoutSubtitle={true} />
            </a>
            <button
              onClick={() => setSearchOpen(!searchOpen)}
              className={`h-full w-fit px-4 flex justify-center items-center`}
            >
              {!searchOpen && (
                <IconSearch
                  size={28}
                  color="black"
                  className="[&_path]:stroke-black dark:[&_path]:stroke-white"
                />
              )}
            </button>
          </div>
          <div className="w-full xl:px-[137px]">
            <div
              className={`${searchOpen ? 'h-16' : 'h-0'
                } overflow-hidden transition-[0.5s] mt-4`}
            >
              <div className="h-full">
                {searchOpen && (
                  <SearchWidget
                    className="bg-white transition duration-300 searchOpen"
                    searchOpen={searchOpen}
                    setSearchOpen={setSearchOpen}
                    withCloseIcon
                  />
                )}
              </div>
            </div>
            {!props.hideMenu && <HeaderDesktop searchOpen={searchOpen} />}
          </div>
        </div>
      </div>
      <HeaderMobile />
    </div>
  );
};

export default connect((state) => ({
  token: state.userSession.token,
}))(Header);
