import { IconX } from './icons/IconX';
import { IconTiktok } from './icons/IconTiktok';
import { IconInstagram } from './icons/IconInstagram';
import { IconFlickr } from './icons/IconFlickr';
import { IconFacebook } from './icons/IconFacebook';
import { IconLinkedin } from './icons/IconLinkedin';
import { IconYoutube } from './icons/IconYoutube';
import { IconWhastapp, IconWhatsapp } from './icons/IconWhatsapp';

export const FooterSocials = () => {
  return (
    <div className="mt-14 flex flex-wrap justify-center sm:justify-start items-start gap-x-12 gap-y-8">
      <a
        href="https://www.facebook.com/UTFPR/"
        target="_blank"
        title="Facebook"
      >
        <IconFacebook
          size={26}
        />
      </a>
      <a
        href="https://www.flickr.com/photos/168609044@N04/"
        target="_blank"
        title="Flickr"
      >
        <IconFlickr
          size={26}
        />
      </a>
      <a
        href="https://www.instagram.com/utfpr_/"
        target="_blank"
        title="Instagram"
      >
        <IconInstagram
          size={26}
        />
      </a>
      <a
        href="https://www.linkedin.com/school/utfpr-oficial/?originalSubdomain=br"
        target="_blank"
        title="LinkedIn"
      >
        <IconLinkedin
          size={26}
        />
      </a>
      <a
        href="https://www.tiktok.com/@utfpr"
        target="_blank"
        title="TikTok"
      >
        <IconTiktok
          size={26}
        />
      </a>
      <a
        href="https://twitter.com/utfpr_"
        target="_blank"
        title="X"
      >
        <IconX
          size={26}
        />
      </a>
      <a
        href="https://www.youtube.com/canaldaUTFPR"
        target="_blank"
        title="YouTube"
      >
        <IconYoutube
          size={26}
        />
      </a>
      <a
        href="https://whatsapp.com/channel/0029Vb3tWIO3wtb3Jvag6Z0H"
        target="_blank"
        title="WhatsApp"
      >
        <IconWhatsapp
          size={26}
        />
      </a>
    </div>
  )
}