/**
 * View text block.
 * @module components/manage/Blocks/Text/View
 */

import PropTypes from 'prop-types';
import redraft from 'redraft';
import React from 'react';
import config from '@plone/volto/registry';
import { CustomContainer } from 'addons/utfpr_components/src/components/general';

/**
 * View text block class.
 * @class View
 * @extends Component
 */

const View = ({ data }) => {
  return data.text ? (
    <CustomContainer
      size="ensino-institucional"
      className="mx-auto"
      alignCenter={true}
    >
      <div className="dark:text-[#F8FAFA] text-dsGray-900">
        {redraft(
          data.text,
          config.settings.richtextViewSettings.ToHTMLRenderers,
          config.settings.richtextViewSettings.ToHTMLOptions,
        )}
      </div>
    </CustomContainer>
  ) : (
    <br />
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
