import ourteam from './components/OurTeam';
import ourPeople from './components/OurPeople/';
import teaser from './components/Teaser';
import banner from './components/Banner';
import gridButtons from './components/GridButtons/';
import textComponent from './components/TextComponent';
import titleComponent from './components/TitleComponent';
import imageMosaic from './components/ImageMosaic';
import brandsPanel from './components/BrandsPanel';
import buttonsPanel from './components/ButtonsPanel';
import hiperLink from './components/Hiperlink';
import postit from './components/PostIt';
import subscribeButton from './components/SubscribeButton';
import highlightButton from './components/HighlightButton';
import divider from './components/Divider';
import contact from './components/Contact';
import allCampis from './components/AllCampis';
import subject from './components/Subject';
import socialMedia from './components/SocialMedia';
import completeTable from './components/CompleteTable';
import twoColumns from './components/TwoColumns';
import singleColumn from './components/singleColumn';
import utfprNumbers from './components/UtfprNumbers/config.js';
import login from './components/Login';
import campi from './components/Campi';
import extensionProjectCards from './components/ExtensionProjectCards';
import TechnologicalShowcase from './components/TechnologicalShowcase/config.js';
import imageCard from './components/imageCard';

const applyConfig = (config) => {
  config.settings.image_crop_aspect_ratios = [
    {
      label: '16:9',
      ratio: 16 / 9,
    },
    {
      label: '4:3',
      ratio: 4 / 3,
    },
    {
      label: '1:1',
      ratio: 1,
    },
  ];
  config.settings.nonContentRoutes = [
    ...config.settings.nonContentRoutes,
    '/view',
  ];

  config.blocks.blocksConfig.ourPeople = ourPeople;
  //config.blocks.blocksConfig.ourTeam = ourteam;
  config.blocks.blocksConfig.teaser = teaser;
  config.blocks.blocksConfig.banner = banner;
  config.blocks.blocksConfig.gridButtons = gridButtons;
  config.blocks.blocksConfig.textComponent = textComponent;
  config.blocks.blocksConfig.titleComponent = titleComponent;
  config.blocks.blocksConfig.imageMosaic = imageMosaic;
  config.blocks.blocksConfig.UtfprNumbers = utfprNumbers;
  config.blocks.blocksConfig.brandsPanel = brandsPanel;
  config.blocks.blocksConfig.buttonsPanel = buttonsPanel;
  config.blocks.blocksConfig.hiperLink = hiperLink;
  config.blocks.blocksConfig.postit = postit;
  config.blocks.blocksConfig.subscribeButton = subscribeButton;
  config.blocks.blocksConfig.highlightButton = highlightButton;
  config.blocks.blocksConfig.divider = divider;
  config.blocks.blocksConfig.contactCard = contact;
  config.blocks.blocksConfig.allCampis = allCampis;
  config.blocks.blocksConfig.subject = subject;
  config.blocks.blocksConfig.socialMedia = socialMedia;
  config.blocks.blocksConfig.completeTable = completeTable;
  config.blocks.blocksConfig.slider.title = 'Carrossel';
  config.blocks.blocksConfig.slider.group = 'media';
  config.blocks.blocksConfig.slider.mostUsed = false;
  config.blocks.blocksConfig.imagesGrid.group = 'media';
  config.blocks.blocksConfig.imagesGrid.mostUsed = false;
  config.blocks.blocksConfig.teaserGrid.mostUsed = false;
  config.blocks.blocksConfig.twoColumns = twoColumns;
  config.blocks.blocksConfig.singleColumn = singleColumn;
  config.blocks.blocksConfig.login = login;
  config.blocks.blocksConfig.campi = campi;
  config.blocks.blocksConfig.extensionProjectCards = extensionProjectCards;
  config.blocks.blocksConfig.TechnologicalShowcase = TechnologicalShowcase;
  config.blocks.blocksConfig.imageCard = imageCard;

  return config;
};

export default applyConfig;
