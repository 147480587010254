const PostItEspecializacaoLayout = (props) => {

  return (
    <div
      style={{ backgroundColor: props.color }}
      className="font-dsBody p-6 flex flex-col md:flex-row justify-between"
    >
      <div className="md:w-1/2 flex flex-col gap-y-2 md:gap-y-10">
        <div>
          <p className="text-base font-medium">EaD</p>
          <p className="text-xl sm:text-2xl text-left font-bold" translate="no">{props.campus}</p>
        </div>

        <div>
          <p className="text-base font-bold">Duração:</p>
          <p className="text-base text-left">{props.duracao}</p>
        </div>
      </div>

      <div className="md:w-1/2 flex flex-col gap-y-10 md:justify-between" >
        <div className="flex flex-col mt-2 md:mt-0">
          <div className="flex gap-x-2">
            <p className="text-base font-bold text-left">Abertura de turmas:</p>
            <p className="text-base text-left">{props.turmas}</p>
          </div>
          <div className="flex gap-x-2">
            <p className="text-base font-bold text-left">Situação das inscrições:</p>
            <p className="text-base text-left">{props.situacao}</p>
          </div>
          <div className="flex gap-x-2">
            <p className="text-base whitespace-normal break-normal w-full text-left">
              <strong>Período de inscrição</strong> {props.periodo}
            </p>
          </div>

          <div className="flex gap-x-2">
            <p className="text-base font-bold text-left">Início das aulas:</p>
            <p className="text-base text-left">{props.aulas}</p>
          </div>
        </div>

        <div className="flex flex-col gap-2 w-full">
          {props.label !== undefined &&
            props.label.length > 0 &&
            props.label.map((label, index) => {
              return (
                <p
                  className="w-full text-center border border-black py-1 px-6 rounded-3xl font-medium text-base"
                  key={index}
                >
                  {label.text}
                </p>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default PostItEspecializacaoLayout;