import { twMerge } from 'tailwind-merge';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles.css';
import { HeaderItem } from './HeaderItem';

export const HeaderDesktop = ({ searchOpen }) => {
  return (
    <ul
      id="navContainer"
      className="list-none hidden md:flex gap-x-2 justify-between items-start mt-4 dark:[&_ul]:bg-[#003063]"
    >
      <HeaderItem
        id="ensino"
        name="Ensino"
        searchOpen={searchOpen}
        dropdownItems={[
          { name: 'Estude na UTFPR', to: '/cursos/estudenautfpr' },
          { name: 'Graduação', to: '/cursos/graduacao' },
          { name: 'Pós-graduação', to: '/pesquisa-e-pos-graduacao/cursos' },
          {
            name: 'Programas de Pós-Graduação',
            to: '/cursos/mestrado-e-doutorado',
          },
          { name: 'Educação a distância', to: '/cursos/educacao-a-distancia' },
          { name: 'Outros cursos', to: '/cursos/outros' },
        ]}
      ></HeaderItem>
      <HeaderItem
        id="pesquisa"
        name="Pesquisa"
        searchOpen={searchOpen}
        dropdownItems={[
          { name: 'Iniciação Científica', to: '/pesquisa-e-pos-graduacao/bolsas/pic' },
          { name: 'Mestrado e Doutorado', to: '/pesquisa-e-pos-graduacao' },
          {
            name: 'Grupos de Pesquisa',
            to: '/pesquisa-e-pos-graduacao/grupos-de-pesquisa',
          },
          {
            name: 'Laboratórios',
            to: '/pesquisa-e-pos-graduacao/laboratorios-multiusuarios',
          },
        ]}
      ></HeaderItem>
      <HeaderItem
        id="inovacao"
        name="Inovação"
        searchOpen={searchOpen}
        dropdownItems={[
          { name: 'Agência de Inovação', to: '/inovacao/agencia' },
          {
            name: 'Política de Inovação',
            to: '/inovacao/politica-de-inovacao',
          },
          { name: 'Empreendedorismo', to: '/inovacao/empreendedorismo' },
          {
            name: 'Incubadora',
            to: '/inovacao/empreendedorismo/incubadora-de-inovacoes-da-utfpr',
          },
          { name: 'Parque tecnológico', to: '/inovacao/parque-tecnologico' },
          {
            name: 'Empresa Júnior',
            to: '/inovacao/empreendedorismo/empresa-junior',
          },
          { name: 'Vitrine Tecnológica', to: '/inovacao/vitrine-tecnologica' },
        ]}
      ></HeaderItem>
      <HeaderItem
        id="extensao"
        name="Extensão"
        searchOpen={searchOpen}
        dropdownItems={[
          { name: 'Cursos Livres', to: '/cursos/outros/extensao' },
          { name: 'Cultura', to: '/cultura' },
          { name: 'Ações Sociais', to: '/' },
          { name: 'Extensão', to: '/extensao' },
          { name: 'Voluntariado', to: '/' },
        ]}
      ></HeaderItem>
      <HeaderItem
        id="institucional"
        name="Institucional"
        searchOpen={searchOpen}
        dropdownItems={[
          { name: 'Sobre a UTFPR', to: '/institucional' },
          { name: 'Quem é quem', to: '/institucional/quem-e-quem' },
          { name: 'Estrutura', to: '/estrutura' },
          { name: 'Documentos', to: '/documentos' },
          { name: 'Editais', to: '/editais' },
          { name: 'Comunicação', to: '/comunicacao' },
          { name: 'Comissões', to: '/comissoes' },
          {
            name: 'Avaliação Institucional',
            to: '/servicos-a-comunidade/avaliacao',
          },
        ]}
      ></HeaderItem>
      <li
        className={`h-full cursor-pointer whitespace-nowrap group relative ${
          searchOpen ? 'bottomWhiteBorder' : 'bottomYellowBorder'
        }`}
      >
        <NavLink
          id="informacao"
          className={twMerge(
            'text-base font-bold lg:text-xl text-[#0D1117] hover:text-[#0D1117] dark:text-[#F8FAFA]',
            searchOpen && 'dark:text-[#0D1117]',
          )}
          to="/acesso-a-informacao"
        >
          Acesso à informação
        </NavLink>
      </li>
        <HeaderItem
          id="prestacao"
          name="Transparência e prestação de contas"
          searchOpen={searchOpen}
          dropdownItems={[
            { name: 'Institucional', to: '/acesso-a-informacao/institucional' },
            { name: 'Relatórios de gestão', to: '/documentos/reitoria/documentos-institucionais/prestacao-de-contas' },
            { name: 'Ações e Programas', to: '/acesso-a-informacao/acoes-e-programas' },
            { name: 'Convênios e Transferências', to: '/acesso-a-informacao/convenios-e-transferencias' },
            { name: 'Receitas e Despesas', to: '/acesso-a-informacao/receitas-e-despesas' },
            { name: 'Demonstrações contábeis', to: '/transparencia/demonstracoes-contabeis-consolidadas' },
            { name: 'Licitações e Contratos', to: '/acesso-a-informacao/licitacoes-e-contratos' },
            { name: 'Servidores', to: '/acesso-a-informacao/servidores' },
            { name: 'Sustentabilidade', to: '/acesso-a-informacao/sustentabilidade' },
            { name: 'Ouvidoria', to: '/contato/ouvidoria/ouvidoria' },
            { name: 'Serviço de Informação ao Cidadão (SIC)', to: '/acesso-a-informacao/servico-informacao-cidadao-sic' },
            { name: 'Unidade de Correição ', to: '/acesso-a-informacao/assessoria-de-processos-disciplinares/assessoria-de-processos-disciplinares' },
            { name: 'Comissão de Ética', to: '/comissoes/permanentes/comissao-de-etica' },
            { name: 'Conflito de Interesses', to: '/servidores/site/documentos/conflito-de-interesses' },
            { name: 'Auditoria Interna', to: '/transparencia/auditoria' },
          ]}
        ></HeaderItem>
    </ul>
  );
};
