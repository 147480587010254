//@ts-check
import React from 'react';
import { CustomLink } from '../general/CustomLink/CustomLink';
import { highlightCities } from '../../helpers/city';

/**
 *@type {React.FC<{href:string,isEditMode:boolean,openInNewWindow:boolean,outline:boolean,title:string}>}
 */
export const ButtonFromPanel = (props) => {
  return (
    <CustomLink
      className={`max-w-fit font-regular text-[14px] tracking-widest px-4 py-3 border transition-all duration-300 ease-in-out cursor-pointer rounded-lg ${
        props.outline
          ? 'text-dsBlue-700 border-dsBlue-700 hover:border-dsYellow-500 hover:text-dsYellow-500 dark:text-dsBlue-200 dark:border-dsBlue-200 dark:hover:border-dsYellow-500 dark:hover:text-dsYellow-500'
          : 'text-white bg-dsBlue-700 dark:bg-dsBlue-200 dark:text-darkModeBg border-dsBlue-700 dark:border-dsBlue-200 hover:bg-dsYellow-500 hover:border-dsYellow-500 hover:text-white dark:hover:bg-dsYellow-500 dark:hover:border-dsYellow-500 dark:hover:text-white '
      } ${props.isEditMode && 'pointer-events-none'}`}
      href={props.href}
      isEditMode={props.isEditMode}
      openInNewWindow={props.openInNewWindow}
    >
      {highlightCities(props.title)}
    </CustomLink>
  );
};
