import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  EffectFade,
  Autoplay,
  Pagination,
} from 'swiper/core';
import getPageContent from '../../services/searchContent';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import './ourPeople.css';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import usePreventBodyScroll from './usePreventBodyScroll';

import { CustomContainer } from '../general/CustomContainers/CustomContainer';
import { OurPeopleContactCard } from './layout/OurPeopleContactCard';
import { OurPeopleTestimonyCard } from './layout/OurPeopleTestimony';
SwiperCore.use([Navigation, EffectFade, Autoplay, Pagination]);

const filterContent = (filter, content) => {
  const filterIds = filter.map((personObj) => {
    if (personObj.person?.[0]) {
      return personObj.person[0].id;
    } else {
      return '';
    }
  });
  const newFilteredContent = content.filter((item) =>
    filterIds.includes(item.id),
  );
  return newFilteredContent;
};

const OurPeopleView = (props) => {
  const urlImageHandler = (url) =>
    isInternalURL(url) ? `${flattenToAppURL(url)}` : url;
  const urlHandler = (url) => (isInternalURL(url) ? flattenToAppURL(url) : url);
  const { data, properties, isEditMode } = props;

  const path = properties['@id'];
  /** @type {unknown[]} */
  const defaultOurPeople = [];

  const [ourPeople, setOurPeople] = useState(defaultOurPeople);

  const content = getPageContent(
    urlHandler(path + '/pessoas'),
    'utf.perfil',
    'perfis',
    0,
    // extremely high limit so it can fetch all profiles in the paste, i hope that this dosen't lag the backend
    2000,
  );

  useEffect(() => {
    if (data.people?.length > 0 && content.length > 0) {
      const peopleDeepCopy = structuredClone(data.people);
      const filteredContent = filterContent(peopleDeepCopy, content);
      if (filteredContent.length > 0) {
        filteredContent.forEach(
          ({ id, image, comment, phone, fax, email, role }) => {
            peopleDeepCopy.forEach((peopleObj) => {
              if (peopleObj.person?.[0]) {
                const personId = peopleObj.person[0].id;
                if (id === personId) {
                  peopleObj.person[0].image = image;
                  peopleObj.person[0].comment = comment;
                  peopleObj.person[0].phone = phone;
                  peopleObj.person[0].fax = fax;
                  peopleObj.person[0].email = email;
                  peopleObj.person[0].role = role;
                }
              }
            });
          },
        );
        setOurPeople(peopleDeepCopy);
      }
    }
  }, [props, content]);

  if (ourPeople.length > 0) {
    return (
      <CustomContainer
        size="ensino-institucional"
        alignCenter={true}
        className={`relative overflow-hidden ${isEditMode ? 'xl:w-[95%] 2xl:w-[95%]' : ''}`}
      >
        <div className="w-full bg-none-swiper [&_.swiper-button-prev]:!w-[16px] [&_.swiper-button-next]:!w-[16px]">
          <Swiper
            slidesPerView={'auto'}
            navigation={ourPeople.length > 3 ? true : false}
            spaceBetween={16}
            breakpoints={{
              0: {
                spaceBetween: 0,
              },
              768: {
                spaceBetween: 16,
              },
            }}
          >
            {ourPeople.map(
              (
                {
                  person,
                  individualLink1Text,
                  individualLink1URL,
                  individualLink2Text,
                  individualLink2URL,
                  individualEmail,
                  individualTel,
                  testimony,
                  institution
                },
                idx,
              ) => {
                if (person?.[0]) {
                  const {
                    id: personId,
                    title,
                    role,
                    comment,
                    image,
                    description,
                  } = person[0];

                  testimony = typeof testimony == 'string' ? testimony : '';

                  if (data.layout == 'testimony')
                    return (
                      <SwiperSlide className="swiper-slide-ourPeople" key={idx}>
                        <OurPeopleTestimonyCard
                          key={personId + toString(idx)}
                          role={role}
                          testimony={testimony}
                          img={urlImageHandler(
                            image ? image.scales.thumb.download : '',
                          )}
                          isEditMode={isEditMode}
                          link1Text={individualLink1Text || 'Leia mais'}
                          link1URL={urlHandler(individualLink1URL)}
                          link2Text={individualLink2Text || 'Leia mais'}
                          link2URL={urlHandler(individualLink2URL)}
                          name={title}
                        ></OurPeopleTestimonyCard>
                      </SwiperSlide>
                    );

                  if (data.globalLinkOption == '0')
                    return (
                      <SwiperSlide className="swiper-slide-ourPeople" key={idx}>
                        <OurPeopleContactCard
                          key={personId + toString(idx)}
                          itemId={personId} // x
                          img={urlImageHandler(
                            image ? image.scales.thumb.download : '',
                          )} // x
                          nome={title} // x
                          cargo={role} // x
                          institution={institution}
                          email={individualEmail} // x
                          tel={individualTel} // x
                          description={urlHandler(description)}
                          comentario={comment} // x
                          ourPeople={ourPeople} // x
                          link1Text={individualLink1Text || 'Leia mais'}
                          link1URL={urlHandler(individualLink1URL)}
                          link2Text={individualLink2Text || 'Leia mais'}
                          link2URL={urlHandler(individualLink2URL)}
                          isEditMode={isEditMode}
                        />
                      </SwiperSlide>
                    );
                  return (
                    <SwiperSlide className="swiper-slide-ourPeople" key={idx}>
                      <OurPeopleContactCard
                        key={personId + toString(idx)}
                        itemId={personId} // x
                        img={urlImageHandler(
                          image ? image.scales.thumb.download : '',
                        )} // x
                        nome={title} // x
                        cargo={role} // x
                        institution={institution}
                        description={urlHandler(description)}
                        comentario={comment} // x
                        ourPeople={ourPeople} // x
                        link1Text={data.globalLinkText || 'Leia mais'}
                        link1URL={urlHandler(data.globalLinkURLOption)}
                        isEditMode={isEditMode}
                      />
                    </SwiperSlide>
                  );
                }
              },
            )}
          </Swiper>
        </div>
      </CustomContainer>
    );
  }
  if (props.token || props.editable)
    return (
      <CustomContainer
        size="ensino-institucional"
        className="mx-auto p-4 bg-slate-200 border border-solid border-black flex flex-col gap-y-2"
        alignCenter
      >
        <p className="text-2xl">Componente "Our People"</p>
        <p>
          Crie uma página com o nome 'pessoas' na rota atual e adicione os
          perfis nesta pasta. Depois disso é só adicionar os perfis nesse
          componente.
        </p>
      </CustomContainer>
    );
  return <></>;
};

export default OurPeopleView;
