//@ts-check
import React from 'react';
import { CustomContainer } from '../general';
//@ts-ignore

import { ButtonFromPanel } from './ButtonFromPanel';

const View = (props) => {
  const { data, isEditMode } = props;
  const content = data.buttons ?? [];

  const buttonsAlign = {
    left: 'justify-center md:justify-start',
    center: 'justify-center',
    right: 'justify-center md:justify-end',
  };

  const align = buttonsAlign[data.align];

  return (
    <div className={`${data.container}`}>
      <CustomContainer
        className={`px-4 sm:px-0 flex flex-wrap ${align} items-start gap-3`}
        size={translateSize(data.container)}
        alignCenter={true}
      > 
      {content.length == 0 && isEditMode ? (<p>Nenhum botão adicionado.</p>) : (null)}
        {content?.map((item, index) => {
          const title = item.title;
          const link = item.link || '#';
          const outline = item.outline;
          const newTab = item.newTab || false;
          return (
            <ButtonFromPanel
              href={link}
              isEditMode={false}
              openInNewWindow={newTab}
              outline={outline}
              title={title}
            ></ButtonFromPanel>
          );
        })}
      </CustomContainer>
    </div>
  );
};

/**
 *
 *
 * @returns {import('../general/CustomContainers/types').Sizes}
 * @param {'0'|'1'|'2'|'3'|undefined} size
 */
function translateSize(size) {
  switch (size) {
    case '0':
      return 'full-width';
    case '1':
      return 'ensino-institucional';
    case '2':
      return 'pesquisa-extensao';
    case '3':
      return 'pesquisa-institucional';
  }
  return 'ensino-institucional';
}

export default View;
