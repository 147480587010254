import { SidebarPortal } from '@plone/volto/components';
import React from 'react';
import ImageComponentView from './ImageView';
import ImageCardBlockData from './ImageData';

const EditComponent = (props) => {
  const { selected } = props;

  return (
    <div>
      <ImageComponentView {...props} isEditMode={true} />
      <SidebarPortal selected={selected}>
        <ImageCardBlockData {...props} />
      </SidebarPortal>
    </div>
  );
};

export default EditComponent;
