export const itemSchema = (props) => ({

  title: 'Hiperlink',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['text', 'link', 'icon'],
    },
  ],
  properties: {
    text: {
      widget: 'text',
      title: 'Texto:',
    },
    link: {
      widget: 'link',
      title: 'Link:'
    },
    icon: {
      type: 'select_widget',
      title: 'Tipo de seta:',
      choices: [['1', 'Seta Diagonal'], ['2', 'Seta Horizontal'], ['3', 'Sem seta']],
    }
  },
  required: ['title', 'link', 'icon'],
});

export const geralSchema = () => ({

  title: 'Customização do hiperlink',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['alignment', 'container', 'columns'],
    },
  ],

  properties: {
    columns: {
      widget: 'object_list',
      title: 'Adicionar novo Hiperlink:',
      schema: itemSchema,
    },
    container: {
      title: 'Container para o componente:',
      widget: 'select',
      choices: [
        ['1', 'Tela inteira'],
        ['2', 'Centralizado'],
        ['3', 'Offset'],
      ]
    },
    alignment: {
      widget: 'align',
      title: 'Alinhamento:',
      actions: ['left', 'right', 'center'],
      default: 'left'
    },
  },
  required: ['alignment'],
});
