/**
 * Breadcrumbs components.
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';

import { Icon } from '@plone/volto/components';
import { getBreadcrumbs } from '@plone/volto/actions';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';

import shareSVG from '@plone/volto/icons/share.svg';
import { CustomContainer } from '../../../../addons/utfpr_components/src/components/general/CustomContainers/CustomContainer';
import { highlightCities } from '../../../../addons/utfpr_components/src/helpers/city';

/**
 * Breadcrumbs container class.
 */
const BreadcrumbsComponent = ({ pathname }) => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.breadcrumbs.items);
  const root = useSelector((state) => state.breadcrumbs.root);

  useEffect(() => {
    if (!hasApiExpander('breadcrumbs', getBaseUrl(pathname))) {
      dispatch(getBreadcrumbs(getBaseUrl(pathname)));
    }
  }, [dispatch, pathname]);

  return (
    <CustomContainer
      size="ensino-institucional"
      alignCenter={true}
      className="mt-4"
    >
      <div className="w-full flex flex-wrap gap-3">
        <Link
          to={root || '/'}
          className="dark:text-[#EFF1F1] dark:hover:text-[var(--ds-yellow-400)] gap-1 hover:text-[var(--ds-yellow-400)] [&_path]:stroke-[#212121] dark:[&_path]:stroke-[#f8fafa]"
        >
          Home
        </Link>
        <Breadcrumb.Divider className="dark:text-[#EFF1F1]" />
        {items.map((item, index, items) => [
          index < items.length - 1 ? (
            <>
              <Link
                key={item.url}
                to={item.url}
                className="dark:text-[#EFF1F1] hover:text-[var(--ds-yellow-400)] dark:hover:text-[var(--ds-yellow-400)]"
              >
                {highlightCities(item.title)}
              </Link>
              <Breadcrumb.Divider
                key={`divider-${item.url}`}
                className="dark:text-[#EFF1F1]"
              />
            </>
          ) : (
            <Breadcrumb.Section
              key={item.url}
              active
              className="text-dsBlue-700 font-bold dark:text-primary-200"
            >
              {highlightCities(item.title)}
            </Breadcrumb.Section>
          ),
        ])}
        <div>
          <Icon name={shareSVG} size="18px" className="dark:text-[#DFE2EB]" />
        </div>
      </div>
    </CustomContainer>
  );
};

BreadcrumbsComponent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default BreadcrumbsComponent;
