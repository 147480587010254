import React from 'react';
import { Segment, Form } from 'semantic-ui-react';
import { ObjectListWidget } from '@plone/volto/components';
import { newsSchema } from './schema';

const BannerData = (props) => {
  const { data, block, onChangeBlock } = props;

  const SchemaExtender = (schema, value) => {
    //console.log('SchemaExtender', schema, value);
    const objType = value.newsObj?.[0]?.['@type'];
    //console.log('objType', objType);
    // eslint-disable-next-line no-undef
    const schemaCopy = structuredClone(schema);
    if (objType === 'News Item') {
      const index = schemaCopy.fieldsets[0].fields.indexOf('newsObj');
      schemaCopy.fieldsets[0].fields.splice(index + 1, 0, 'customImg');
      schemaCopy.properties.customImg = {
        title: 'Usar uma imagem customizada',
        description:
          'Selecione se você quer usar uma imagem customizada em vez da imagem atualmente atrelado a notícia (se houver)',
        type: 'boolean',
      };
      if (value.customImg) {
        const index = schemaCopy.fieldsets[0].fields.indexOf('customImg');
        schemaCopy.fieldsets[0].fields.splice(index + 1, 0, 'customImgObj');
        schemaCopy.properties.customImgObj = {
          title: 'Selecione a imagem',
          widget: 'object_browser',
          mode: 'single',
          selectableTypes: ['Image'],
        };
      }
    }

    if (objType === 'Image') {
      value.customImgObj = [];
      value.customImg = false;
      const index = schemaCopy.fieldsets[0].fields.indexOf('customTitle');
      schemaCopy.fieldsets[0].fields.splice(index + 1, 0, 'imageLink');
      schemaCopy.properties.imageLink = {
        title: 'Link',
        type: 'string',
      };
      schemaCopy.required.push('imageLink');
    }

    if (value.customTitle) {
      const index = schemaCopy.fieldsets[0].fields.indexOf('customTitle');
      schemaCopy.fieldsets[0].fields.splice(index + 1, 0, 'customTitleTxt');
      schemaCopy.properties.customTitleTxt = {
        title: 'Título',
        type: 'string',
      };
    }
    return schemaCopy;
  };
  return (
    <Segment.Group raised>
      <Form>
        <ObjectListWidget
          id="slides"
          title="Insira slides"
          block={block}
          fieldSet={'default'}
          schema={newsSchema({ ...props })}
          schemaExtender={SchemaExtender}
          onChange={(id, value) => {
            onChangeBlock(props.block, { ...props.data, [id]: value });
          }}
          value={data.slides}
        />
      </Form>
    </Segment.Group>
  );
};

export default BannerData;
