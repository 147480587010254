import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { highlightCities } from '../../../../addons/utfpr_components/src/helpers/city';

const PostItTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  const urlHandler = (url) => (isInternalURL(url) ? flattenToAppURL(url) : url);

  function openLink(link) {
    if (isEditMode) {
      return;
    }
    window.location.href = urlHandler(link);
  }

  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[22px] pb-5">
      {items &&
        items.map((item) => (
          <a
            key={item['@id']}
            href={isEditMode ? '#' : urlHandler(item.getURL)}
            target={isInternalURL(item.getURL) ? '_self' : '_blank'}
            rel={isInternalURL(item.getURL) ? '' : 'noopener noreferrer'}
            className={`w-full min-h-[190px] p-[16px] bg-[#FEF1B9] flex flex-col justify-between gap-[22px] hover:bg-[#FFEDA3] hover:shadow-lg cursor-pointer hover:no-underline hover:text-current transition ${
              isEditMode ? 'pointer-events-none' : ''
            }`}
          >
            <div className="text-sm md:text-lg lg:text-xl xl:text-2xl text-[#31353C] justify-start font-bold">
              {highlightCities(item.title)}
            </div>
            <div className="flex flex-wrap gap-2 sm:max-w-[190px] w-full justify-start">
              {item.Subject &&
                Array.from(
                  new Set(item.Subject.map((subject) => subject.toLowerCase())),
                ).map((subject, idx) => (
                  <span
                    key={idx}
                    className="whitespace-nowrap text-xs border border-black py-1 rounded-3xl text-center flex items-center p-2 justify-start"
                  >
                    {highlightCities(subject)}
                  </span>
                ))}
            </div>
          </a>
        ))}
    </div>
  );
};

export default PostItTemplate;