// Content type 'utf.pefil' attributes: Título (title), Descrição (description), Cargo (role), Foto de perfil (image), Email (email), Telefone (phone), Fax (fax), Comentário (comment)

// this is a schema propertie , look at TestemonyConfigSchema for a example
const layout = {
  widget: 'select',
  title: 'Layout',
  description: 'Escolher o layout do componente',
  default: 'contact',
  choices: [
    ['contact', 'Contato'],
    ['testimony', 'Depoimento'],
  ],
};

export const IndividualPersonSchema = {
  title: 'Perfil',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'person',
        'institution',
        'individualEmail',
        'individualTel',
        'individualLink1Text',
        'individualLink1URL',
        'individualLink2Text',
        'individualLink2URL',
      ],
    },
  ],
  properties: {
    person: {
      widget: 'object_browser',
      title: 'Perfil',
      description: 'Selecione algum perfil do diretório "/pessoas"',
      mode: 'single',
      selectableTypes: ['utf.perfil'],
    },
    institution: {
      widget: 'text',
      title: 'Instituição/Campus',
    },
    individualLink1Text: {
      widget: 'text',
      title: 'Texto para o link 1',
    },
    individualLink1URL: {
      widget: 'text',
      title: 'URL do link 1',
    },
    individualLink2Text: {
      widget: 'text',
      title: 'Texto para o link 2',
    },
    individualLink2URL: {
      widget: 'text',
      title: 'URL do link 2',
    },
    individualEmail: {
      widget: 'text',
      title: 'E-mail',
    },
    individualTel: { widget: 'text', title: 'Telefone' },
  },
  required: ['person', 'individualLinkOption'],
};

export const TestimonyPersonSchema = {
  title: 'Perfil',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'person',
        'testimony',
        'individualLinkText',
        'individualLinkURL',
      ],
    },
  ],
  properties: {
    person: {
      widget: 'object_browser',
      title: 'Perfil',
      description: 'Selecione algum perfil do diretório "/pessoas"',
      mode: 'single',
      selectableTypes: ['utf.perfil'],
    },
    testimony: {
      widget: 'text',
      title: 'Depoimento',
    },
    individualLinkText: {
      widget: 'text',
      title: 'Texto para o link',
    },
    individualLinkURL: {
      widget: 'text',
      title: 'URL do link',
    },
  },
  required: ['person'],
};

export const GlobalPersonSchema = {
  title: 'Perfil',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['person'],
    },
  ],
  properties: {
    person: {
      widget: 'object_browser',
      title: 'Perfil',
      description: 'Selecione algum perfil do diretório "/pessoas"',
      mode: 'single',
      selectableTypes: ['utf.perfil'],
    },
  },
  required: ['person'],
};

export const GlobalConfigSchema = (props) => {
  if (props.data?.globalLinkURLOption === '0') {
    return {
      title: 'Customização geral',
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: [
            'layout',
            'globalLinkOption',
            'globalLinkText',
            'globalLinkURL',
          ],
        },
      ],
      properties: {
        layout,
        globalLinkText: {
          widget: 'text',
          title: 'Texto do link global',
        },
        globalLinkURL: {
          widget: 'text',
          title: 'URL do link global',
        },
        globalLinkOption: {
          widget: 'select',
          title: 'Incluir links em todos os perfis de uma vez?',
          description:
            'Escolha se deseja customizar todos os perfis de uma vez ou individualmente',
          default: '0',
          choices: [
            ['0', 'Não, customizar individualmente'],
            ['1', 'Sim, com texto e link customizado'],
          ],
        },
      },
      required: ['globalLinkURLOption', 'globalLinkOption'],
    };
  }
  // i think that this part of code never gets read , but i don't know it for sure
  return {
    title: 'Customização geral',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'globalLinkOption',
          'globalLinkText',
          'globalLinkURLOption',
          'layout',
        ],
      },
    ],
    properties: {
      layout,
      globalLinkText: {
        widget: 'text',
        title: 'Texto do link global',
      },
      globalLinkURLOption: {
        widget: 'select',
        title: 'URL do link global',
        description: 'Insira um link customizado ou use um campo do perfil',
        default: '0',
        choices: [
          ['0', 'Usar um valor customizado'],
          ['1', 'Usar o campo "Email" do perfil'],
          ['2', 'Usar o campo "Descrição" do perfil'],
        ],
      },
      globalLinkOption: {
        widget: 'select',
        title: 'Incluir links em todos os perfis de uma vez?',
        description:
          'Escolha se deseja customizar todos os perfis de uma vez ou individualmente',
        default: '0',
        choices: [
          ['0', 'Não, customizar individualmente'],
          ['1', 'Sim, com texto e link customizado'],
        ],
      },
    },
    required: ['globalLinkOption', 'globalLinkURLOption'],
  };
};

export const IndividualConfigSchema = {
  title: 'Customização individual',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['layout', 'globalLinkOption'],
    },
  ],
  properties: {
    layout,
    globalLinkOption: {
      widget: 'select',
      title: 'Incluir links em todos os perfis de uma vez?',
      description:
        'Escolha se deseja customizar todos os perfis de uma vez ou individualmente',
      default: '1',
      choices: [
        ['0', 'Não, customizar individualmente'],
        ['1', 'Sim, com texto e link customizado'],
      ],
    },
  },
  required: ['globalLinkOption'],
};

export const TestimonyConfigSchema = {
  title: 'Customização individual de depoimento',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['layout'],
    },
  ],
  properties: {
    layout,
  },
  required: [],
};
