/**
 * App container.
 * @module components/theme/App/App
 */

import React, { Component } from 'react';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { asyncConnect, Helmet } from '@plone/volto/helpers';
import { Segment } from 'semantic-ui-react';
import { renderRoutes } from 'react-router-config';
import { Slide, ToastContainer, toast } from 'react-toastify';
import split from 'lodash/split';
import join from 'lodash/join';
import trim from 'lodash/trim';
import cx from 'classnames';
import config from '@plone/volto/registry';
import { PluggablesProvider } from '@plone/volto/components/manage/Pluggable';
import { visitBlocks } from '@plone/volto/helpers/Blocks/Blocks';
import { injectIntl } from 'react-intl';
import { useDarkModeContext } from '../../../../store/DarkModeStore';
import ReportErrorButton from '../ReportErrorButton/ReportErrorButton';
import Error from '@plone/volto/error';
import { withRouter } from 'react-router-dom';
import {
  Breadcrumbs,
  Footer,
  Header,
  Icon,
  OutdatedBrowser,
  AppExtras,
  SkipLinks,
} from '@plone/volto/components';
import {
  BodyClass,
  getBaseUrl,
  getView,
  hasApiExpander,
  isCmsUi,
} from '@plone/volto/helpers';
import {
  getBreadcrumbs,
  getContent,
  getNavigation,
  getTypes,
  getWorkflow,
} from '@plone/volto/actions';

import clearSVG from '@plone/volto/icons/clear.svg';
import MultilingualRedirector from '@plone/volto/components/theme/MultilingualRedirector/MultilingualRedirector';
import WorkingCopyToastsFactory from '@plone/volto/components/manage/WorkingCopyToastsFactory/WorkingCopyToastsFactory';
import LockingToastsFactory from '@plone/volto/components/manage/LockingToastsFactory/LockingToastsFactory';

// JOE: Solução temporária para páginas com URL errado
class WrongPathSolution extends Component {
  componentDidUpdate() {
    const { location, history } = this.props;
    //console.log('location', location);
    //console.log('history', history);

    const pathname = location.pathname;

    switch (pathname) {
      case '/concursos':
        history.push('/editais/concursos');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgsis-dv':
        history.push('/cursos/mestrado-e-doutorado/ppgsis');
        break;
      case '/cursos/coordenacoes/stricto-sensu/profmat-cp':
        history.push('/cursos/programas-de-pos-graduacao/profmat-cp');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgca-ct':
        history.push('/cursos/programas-de-pos-graduacao/ppgca-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/mnpef-md':
        history.push('/cursos/programas-de-pos-graduacao/mnpef-md');
        break;
      case '/cursos/coordenacoes/stricto-sensu/profmat-ct':
        history.push('/cursos/programas-de-pos-graduacao/profmat-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgfa-ct':
        history.push('/cursos/programas-de-pos-graduacao/ppgfa-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/cpgei-ct':
        history.push('/cursos/programas-de-pos-graduacao/cpgei-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgee-pg':
        history.push('/cursos/programas-de-pos-graduacao/ppgee-pg');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgtal-multi':
        history.push('/cursos/programas-de-pos-graduacao/ppgtal-multi');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgea-fb':
        history.push('/cursos/programas-de-pos-graduacao/ppgea-fb');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgbioinfo-cp':
        history.push('/cursos/programas-de-pos-graduacao/ppgbioinfo-cp');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgit-cm':
        history.push('/cursos/programas-de-pos-graduacao/ppgit-cm');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgtp-pb':
        history.push('/cursos/programas-de-pos-graduacao/ppgtp-pb');
        break;
      case '/cursos/coordenacoes/stricto-sensu/profmat-pb':
        history.push('/cursos/programas-de-pos-graduacao/profmat-pb');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgect-pg':
        history.push('/cursos/programas-de-pos-graduacao/ppgect-pg');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgl-pb':
        history.push('/cursos/programas-de-pos-graduacao/ppgl-pb');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgep-pg':
        history.push('/cursos/programas-de-pos-graduacao/ppgep-pg');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgte-ct':
        history.push('/cursos/programas-de-pos-graduacao/ppgte-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppga-ct':
        history.push('/cursos/programas-de-pos-graduacao/ppga-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgec-ct':
        history.push('/cursos/programas-de-pos-graduacao/ppgec-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgeb-ct':
        history.push('/cursos/programas-de-pos-graduacao/ppgeb-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgel-ct':
        history.push('/cursos/programas-de-pos-graduacao/ppgel-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgqb-td':
        history.push('/cursos/programas-de-pos-graduacao/ppgqb-td');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgcta-ct':
        history.push('/cursos/programas-de-pos-graduacao/ppgcta-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppz':
        history.push('/cursos/programas-de-pos-graduacao/ppz');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgeq-pg':
        history.push('/cursos/programas-de-pos-graduacao/ppgeq-pg');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgse-ct':
        history.push('/cursos/programas-de-pos-graduacao/ppgse-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgee-cp':
        history.push('/cursos/programas-de-pos-graduacao/ppgee-cp');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgag-pb':
        history.push('/cursos/programas-de-pos-graduacao/ppgag-pb');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgmat-multi':
        history.push('/cursos/programas-de-pos-graduacao/ppgmat-multi');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgi-cp':
        history.push('/cursos/programas-de-pos-graduacao/ppgi-cp');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgdr-pb':
        history.push('/cursos/programas-de-pos-graduacao/ppgdr-pb');
        break;
      case '/cursos/coordenacoes/stricto-sensu/mnpef-cm':
        history.push('/cursos/programas-de-pos-graduacao/mnpef-cm');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgta-multi':
        history.push('/cursos/programas-de-pos-graduacao/ppgta-multi');
        break;
      case '/cursos/coordenacoes/stricto-sensu/profmat-td':
        history.push('/cursos/programas-de-pos-graduacao/profmat-td');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgrns-sh':
        history.push('/cursos/programas-de-pos-graduacao/ppgrns-sh');
        break;
      case '/cursos/coordenacoes/stricto-sensu/profqui-md':
        history.push('/cursos/programas-de-pos-graduacao/profqui-md');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgcc-cm':
        history.push('/cursos/programas-de-pos-graduacao/ppgcc-cm');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgcc-pg':
        history.push('/cursos/programas-de-pos-graduacao/ppgcc-pg');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgec-pb':
        history.push('/cursos/programas-de-pos-graduacao/ppgec-pb');
        break;
      case '/cursos/coordenacoes/stricto-sensu/profiap-multi':
        history.push('/cursos/programas-de-pos-graduacao/profiap-multi');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgef-ct':
        history.push('/cursos/programas-de-pos-graduacao/ppgef-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgsau-ct':
        history.push('/cursos/programas-de-pos-graduacao/ppgsau-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgtamb/ppgtamb-md':
        history.push('/cursos/programas-de-pos-graduacao/ppgtamb-md');
        break;
      case '/cursos/coordenacoes/stricto-sensu/profnit-md':
        history.push('/cursos/programas-de-pos-graduacao/profnit-md');
        break;
      case '/cursos/coordenacoes/stricto-sensu/profagua-cm':
        history.push('/cursos/programas-de-pos-graduacao/profagua-cm');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgeq-ap':
        history.push('/cursos/programas-de-pos-graduacao/ppgeq-ap');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgtm-ap':
        history.push('/cursos/programas-de-pos-graduacao/ppgtm-ap');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgbio-td':
        history.push('/cursos/programas-de-pos-graduacao/ppgbio-td');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgcem-multi':
        history.push('/cursos/programas-de-pos-graduacao/ppgcem-multi');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgab-cp':
        history.push('/cursos/programas-de-pos-graduacao/ppgab-cp');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgq-ct':
        history.push('/cursos/programas-de-pos-graduacao/ppgq-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgfcet-ct':
        history.push('/cursos/programas-de-pos-graduacao/ppgfcet-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgpgp-ct':
        history.push('/cursos/programas-de-pos-graduacao/ppgpgp-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgem-multi':
        history.push('/cursos/programas-de-pos-graduacao/ppgem-multi');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgem-ct':
        history.push('/cursos/programas-de-pos-graduacao/ppgem-ct');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgtca-md':
        history.push('/cursos/programas-de-pos-graduacao/ppgtca-md');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgee-pb':
        history.push('/cursos/programas-de-pos-graduacao/ppgee-pb');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgbiotec-multi':
        history.push('/cursos/programas-de-pos-graduacao/ppgbiotec-multi');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgen-multi':
        history.push('/cursos/programas-de-pos-graduacao/ppgen-multi');
        break;
      case '/cursos/coordenacoes/stricto-sensu/ppgeps-pb':
        history.push('/cursos/programas-de-pos-graduacao/ppgeps-pb');
        break;
      default:
        break;
    }
  }

  render() {
    return this.props.children;
  }
}

const WrongPathSolutionWithRouter = withRouter(WrongPathSolution);

/**
 * @export
 * @class App
 * @extends {Component}
 */
export class App extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
  };

  state = {
    hasError: false,
    error: null,
    errorInfo: null,
    darkMode: 'off',
  };

  constructor(props) {
    super(props);
    this.mainRef = React.createRef();
  }

  /**
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    }
  }

  /**
   * ComponentDidCatch
   * @method ComponentDidCatch
   * @param {string} error  The error
   * @param {string} info The info
   * @returns {undefined}
   */
  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, errorInfo: info });
    config.settings.errorHandlers.forEach((handler) => handler(error));
  }

  componentDidMount() {
    const darkMode = localStorage.getItem('darkMode') ?? 'off';

    this.setState({
      darkMode: darkMode,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.darkMode !== this.state.darkMode) {
      localStorage.setItem('darkMode', this.state.darkMode);
    }
  }

  componentWillUnmount() {
    localStorage.setItem('darkMode', 'off');
  }

  dispatchContentClick = (event) => {
    if (event.target === event.currentTarget) {
      const rect = this.mainRef.current.getBoundingClientRect();
      if (event.clientY > rect.bottom) {
        document.dispatchEvent(new Event('voltoClickBelowContent'));
      }
    }
  };

  setDarkMode = (bool) => {
    localStorage.setItem('darkMode', bool);
    this.setState({ darkMode: bool });
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { views } = config;
    const path = getBaseUrl(this.props.pathname);
    const action = getView(this.props.pathname);
    const isCmsUI = isCmsUi(this.props.pathname);
    const ConnectionRefusedView = views.errorViews.ECONNREFUSED;

    const language =
      this.props.content?.language?.token ?? this.props.intl?.locale;

    return (
      <WrongPathSolutionWithRouter>
        <useDarkModeContext.Provider
          value={{
            darkMode: this.state.darkMode,
            setDarkMode: this.setDarkMode,
          }}
        >
          <PluggablesProvider>
            {language && (
              <Helmet>
                <html lang={language} />
              </Helmet>
            )}
            <BodyClass className={`view-${action}view`} />

            {/* Body class depending on content type */}
            {this.props.content && this.props.content['@type'] && (
              <BodyClass
                className={`contenttype-${this.props.content['@type']
                  .replace(' ', '-')
                  .toLowerCase()}`}
              />
            )}

            {/* Body class depending on sections */}
            <BodyClass
              className={cx({
                [trim(join(split(this.props.pathname, '/'), ' section-'))]:
                  this.props.pathname !== '/',
                siteroot: this.props.pathname === '/',
                'is-authenticated': !!this.props.token,
                'is-anonymous': !this.props.token,
                'cms-ui': isCmsUI,
                'public-ui': !isCmsUI,
                dark: this.state.darkMode === 'on',
                'bg-[#FAFDFD]': this.state.darkMode === 'off',
                'bg-[#001326]': this.state.darkMode === 'on',
              })}
            />
            <SkipLinks />
            <Header
              pathname={path}
              hideMenu={path.startsWith(
                '/cursos/estudenautfpr/vestibular/vestibular',
              )}
            />
            {path !== '' &&
              !path.startsWith('/cursos/estudenautfpr/vestibular/vestibular') && (
                <Breadcrumbs pathname={path} />
              )}
            <MultilingualRedirector
              pathname={this.props.pathname}
              contentLanguage={this.props.content?.language?.token}
            >
              <Segment
                basic
                className="content-area !m-0 !p-0 [&>main>div>div.newsitem-view]:gap-y-6 [&>main>div>div.newsitem-view]:grid mx-auto"
                onClick={this.dispatchContentClick}
              >
                <main ref={this.mainRef}>
                  <OutdatedBrowser />
                  {this.props.connectionRefused ? (
                    <ConnectionRefusedView />
                  ) : this.state.hasError ? (
                    <Error
                      message={this.state.error.message}
                      stackTrace={this.state.errorInfo.componentStack}
                    />
                  ) : (
                    renderRoutes(this.props.route.routes, {
                      staticContext: this.props.staticContext,
                    })
                  )}
                </main>
              </Segment>
            </MultilingualRedirector>
            <ReportErrorButton className="mb-4" />
            <Footer />
            <LockingToastsFactory
              content={this.props.content}
              user={this.props.userId}
            />
            <WorkingCopyToastsFactory content={this.props.content} />
            <ToastContainer
              position={toast.POSITION.BOTTOM_CENTER}
              hideProgressBar
              transition={Slide}
              autoClose={5000}
              closeButton={
                <Icon
                  className="toast-dismiss-action"
                  name={clearSVG}
                  size="18px"
                />
              }
            />
            <AppExtras {...this.props} />
          </PluggablesProvider>
        </useDarkModeContext.Provider>
      </WrongPathSolutionWithRouter>
    );
  }
}

export const __test__ = connect(
  (state, props) => ({
    pathname: props.location.pathname,
    token: state.userSession.token,
    content: state.content.data,
    apiError: state.apierror.error,
    connectionRefused: state.apierror.connectionRefused,
  }),
  {},
)(App);

export const fetchContent = async ({ store, location }) => {
  const content = await store.dispatch(
    getContent(getBaseUrl(location.pathname)),
  );

  const promises = [];
  const { blocksConfig } = config.blocks;

  const visitor = ([id, data]) => {
    const blockType = data['@type'];
    const { getAsyncData } = blocksConfig[blockType];
    if (getAsyncData) {
      const p = getAsyncData({
        store,
        dispatch: store.dispatch,
        path: location.pathname,
        location,
        id,
        data,
        blocksConfig,
      });
      if (!p?.length) {
        throw new Error(
          'You should return a list of promises from getAsyncData',
        );
      }
      promises.push(...p);
    }
  };

  visitBlocks(content, visitor);

  await Promise.allSettled(promises);

  return content;
};

export function connectAppComponent(AppComponent) {
  return compose(
    asyncConnect([
      {
        key: 'breadcrumbs',
        promise: ({ location, store: { dispatch } }) => {
          // Do not trigger the breadcrumbs action if the expander is present
          if (
            __SERVER__ &&
            !hasApiExpander('breadcrumbs', getBaseUrl(location.pathname))
          ) {
            return dispatch(getBreadcrumbs(getBaseUrl(location.pathname)));
          }
        },
      },
      {
        key: 'content',
        promise: ({ location, store }) =>
          __SERVER__ && fetchContent({ store, location }),
      },
      {
        key: 'navigation',
        promise: ({ location, store: { dispatch } }) => {
          // Do not trigger the navigation action if the expander is present
          if (
            __SERVER__ &&
            !hasApiExpander('navigation', getBaseUrl(location.pathname))
          ) {
            return dispatch(
              getNavigation(
                getBaseUrl(location.pathname),
                config.settings.navDepth,
              ),
            );
          }
        },
      },
      {
        key: 'types',
        promise: ({ location, store: { dispatch } }) => {
          // Do not trigger the types action if the expander is present
          if (
            __SERVER__ &&
            !hasApiExpander('types', getBaseUrl(location.pathname))
          ) {
            return dispatch(getTypes(getBaseUrl(location.pathname)));
          }
        },
      },
      {
        key: 'workflow',
        promise: ({ location, store: { dispatch } }) =>
          __SERVER__ && dispatch(getWorkflow(getBaseUrl(location.pathname))),
      },
    ]),
    injectIntl,
    connect(
      (state, props) => ({
        pathname: props.location.pathname,
        token: state.userSession.token,
        userId: state.userSession.token
          ? jwtDecode(state.userSession.token).sub
          : '',
        content: state.content.data,
        apiError: state.apierror.error,
        connectionRefused: state.apierror.connectionRefused,
      }),
      null,
    ),
  )(AppComponent);
}

export default connectAppComponent(App);
