import {useEffect, useState} from "react";
import Year from "./Year.jsx"
import NumberIndicative from "./NumberIndicative.jsx";
import StatisticIndicative from "./StatisticIndicative.jsx";
import MoneyIndicative from "./MoneyIndicative.jsx";
import NegativeIndicative from "./NegativeIndicative.jsx";
import MetricsIndicative from "./MetricsIndicative.jsx";

const ViewTechnologicalShowcase = (props)=>{

    const [data, setData] = useState([]);
    const [metricsDesc, setMetricsDesc] = useState("Descrição");
    const [metrics, setMetrics] = useState([]);
    const [year, setYear] = useState("####");
    const [numberIndicative, setNumberIndicative] = useState({title: "Título", value: "###", desc: "Descrição"});
    const [statistic, setStatistic] = useState({title: "Título", value: "###", desc: "Descrição"});
    const [moneyIndicative, setMoneyIndicative] = useState({title: "Título", value: "###", desc: "Descrição"});
    const [percentageIndicative, setPercentageIndicative]= useState({title: "Título", value: "###", desc: "Descrição"});
    const [positionYear, setPositionYear] = useState(0);
    const [years, setYears] = useState([]);
    const [activeYear, setActiveYear] = useState(1);

    useEffect(()=>{

        if(typeof props.data.number != 'undefined'){
           
            constructYearPages();
            
        }

    },[props.data.number]);

    const constructYearPages = ()=>{
        let arrYears = props.data.number;
        setYears(props.data.number);
    }

    const nextYear = ()=>{
        if(activeYear !== (years.length)){
            setActiveYear(activeYear+1);
        }
    }

    const backYear = ()=>{
        if(activeYear > 1){
            setActiveYear(activeYear-1);
        }
    }

    const extractMetrics = (obj) => {
        if(typeof obj !== "null" && typeof obj !== "undefined"){
            let keys = Object.keys(obj);
            let regex = new RegExp("metric\\d{1,2}$")
            let metricKeys = keys.filter(str=> regex.test(str));

            if(metricKeys.length > 0){
                let auxArr = [];
                metricKeys.forEach((element)=>{
                    let values = obj[element].split(";");
                    auxArr.push({title: values[0], desc: values[1]});
                })
                setMetrics(auxArr);
            }else{
                setMetrics([]);
            }
        }
        
    }

    //Código para reordenar o state data.

    return(
        <div className="mx-auto [&_.custom-container]:px-0 sm:px-0 sm:w-[612px] md:w-[740px] lg:w-[718px] xl:w-[974px] 2xl:w-[1230px] transition-[0.5s] mx-auto flex flex-col items-center">
            {typeof years[activeYear - 1] != "undefined" ? (
                <>
                    <div className="w-full lg:w-auto xl:w-auto 2xl:w-auto box flex flex-col gap-[15px] items-center lg:justify-center lg:flex-row">
                <div className="w-full lg:w-auto xl:w-auto 2xl:w-auto flex flex-col pt-[10px] pl-[10px] gap-[15px]">
                    <div className="w-full lg:w-auto xl:w-auto 2xl:w-auto">
                        <Year year={typeof years[activeYear - 1].year != "undefined" ? (years[activeYear - 1].year) : (year)} nextYear={nextYear} backYear={backYear}></Year>
                    </div>
                    <div className="w-full lg:w-auto xl:w-auto 2xl:w-auto">
                        <NumberIndicative value={typeof years[activeYear - 1].indicativeNumberValue != "undefined" ? (years[activeYear - 1].indicativeNumberValue) : (numberIndicative.value)} title={typeof years[activeYear - 1].indicativeNumberTitle != "undefined" ? (years[activeYear - 1].indicativeNumberTitle) : (numberIndicative.title)} desc={typeof years[activeYear - 1].indicativeNumberDesc != "undefined" ? (years[activeYear - 1].indicativeNumberDesc) : (numberIndicative.desc)}></NumberIndicative>
                    </div>
                    <div className="w-full lg:w-auto xl:w-auto 2xl:w-auto">
                        <StatisticIndicative value={typeof years[activeYear - 1].statisticValue != "undefined" ? (years[activeYear - 1].statisticValue) : (statistic.value)} title={typeof years[activeYear - 1].statisticTitle != "undefined" ? (years[activeYear - 1].statisticTitle) : (statistic.title)} desc={typeof years[activeYear - 1].statisticDesc != "undefined" ? (years[activeYear - 1].statisticDesc) : (statistic.desc)}></StatisticIndicative>
                    </div>
                </div>
                <div className="w-full pl-[10px] lg:pl-[0px] lg:w-auto xl:w-auto 2xl:w-auto pt-[10px]">
                    <MetricsIndicative data={typeof years[activeYear - 1].metrics != "undefined" ? (years[activeYear - 1].metrics) : ([])} desc={typeof years[activeYear - 1].metricsDesc != "undefined" ? (years[activeYear - 1].metricsDesc) : ("Descrição")}></MetricsIndicative>
                </div>
            </div>
            <div className="w-full lg:w-auto xl:w-auto 2xl:w-auto flex flex-col box pt-[15px] pl-[10px] gap-[15px] lg:justify-center lg:flex-row">
                <div>
                    <MoneyIndicative value={typeof years[activeYear - 1].indicativeMoneyValue != "undefined" ? (years[activeYear - 1].indicativeMoneyValue) : (moneyIndicative.value)} title={typeof years[activeYear - 1].indicativeMoneyTitle != "undefined" ? (years[activeYear - 1].indicativeMoneyTitle) : (moneyIndicative.title)} desc={typeof years[activeYear - 1].indicativeMoneyDesc != "undefined" ? (years[activeYear - 1].indicativeMoneyDesc) : (moneyIndicative.desc)}></MoneyIndicative>
                </div>
                <div>
                    <NegativeIndicative value={typeof years[activeYear - 1].indicativePercentageValue != "undefined" ? (years[activeYear - 1].indicativePercentageValue) : (percentageIndicative.value)} title={typeof years[activeYear - 1].indicativePercentageTitle != "undefined" ? (years[activeYear - 1].indicativePercentageTitle) : (percentageIndicative.title)} desc={typeof years[activeYear - 1].indicativePercentageDesc != "undefined" ? (years[activeYear - 1].indicativePercentageDesc) : (percentageIndicative.desc)}></NegativeIndicative>
                </div>
            </div>
                </>
            ) : ("Nenhum ano cadastrado!")}
            
        </div>
    )
}

export default ViewTechnologicalShowcase;