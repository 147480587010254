import { highlightCities } from '../../../helpers/city';

const PostItCartaoLayout = (props) => {
  const isEmail = /\S+@\S+\.\S+/.test(props.bodyText);
  return (
    <div
      className="font-dsBody p-7 flex flex-col gap-y-6"
      onClick={props.onClick}
      style={{ backgroundColor: props.color, wordWrap: 'break-word'}}
    >
      <a href={props.href} className="font-bold hover:underline">
        <p className="font-bold text-xl sm:text-2xl text-left">
          {highlightCities(props.title)}
        </p>
      </a>
      {isEmail ? (
        <a
          href={`mailto:${props.bodyText}`}
          style={{
            fontWeight: 400,
          }}
        >
          (props.bodyText)
        </a>
      ) : (
        <p style={{ fontWeight: 400 }}>{highlightCities(props.bodyText)}</p>
      )}
      <div className="text-center grid gap-2 md:grid-rows-2 md:grid-cols-2 grid-cols-1">
        {props.labels !== undefined &&
          props.labels.length > 0 &&
          props.labels.map((label, index) => {
            return (
              <p
                style={{ fontWeight: 500 }}
                className="boder-solid border-black border px-6 py-1 rounded-3xl"
                key={index}
              >
                {highlightCities(label.text)}
              </p>
            );
          })}
      </div>
    </div>
  );
};

export default PostItCartaoLayout;