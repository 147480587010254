const Year = (props)=>{
    return(<>
        <div className="dark:bg-[#001C3A] flex flex-row justify-between bg-[#FFFFFF] w-full lg:w-[385px] rounded-lg items-center shadow-md pl-[20px] pr-[20px] h-[70px]">
            <svg width="20" height="29" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>props.backYear()}>
                <path d="M14.82 21.18L5.66 12L14.82 2.82L12 5.76437e-10L5.7703e-10 12L12 24L14.82 21.18Z" fill="#BFBBBB"/>
            </svg>
            <h4 className="text-[#005DB7] text-[29px] font-bold dark:text-white">{props.year}</h4>
            <svg  width="20" height="29" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>props.nextYear()}>
                <path d="M0.179688 21.18L9.33969 12L0.179688 2.82L2.99969 1.35463e-10L14.9997 12L2.99969 24L0.179688 21.18Z" fill="#BFBBBB"/>
            </svg>
        </div>
    </>)
}

export default Year;