import { twMerge } from 'tailwind-merge';
import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

/**
 * @typedef DropdownItem
 * @property {string} to - link to open when item is clicked
 * @property {string} name
 */

/**
 * @type {React.FC<{
 *  searchOpen:boolean,
 *  name:string,
 *  id:string,
 *  dropdownItems:DropdownItem[] // link to open when item is clicked and item name
 * }>}
 */
export const HeaderItem = ({ searchOpen, name, id, dropdownItems }) => {
  const [isItemOpen, setIsItemOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsItemOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <li
      ref={dropdownRef}
      className={`cursor-pointer whitespace-nowrap group relative ${
        searchOpen ? 'bottomWhiteBorder' : 'bottomYellowBorder'
      } ${isItemOpen ? 'open-list' : ''}`}
    >
      <button
        id={id}
        className={twMerge(
          'text-base font-bold lg:text-xl text-[#0D1117] hover:text-[#0D1117] dark:text-[#F8FAFA]',
          searchOpen && 'dark:text-[#0D1117]',
        )}
        onClick={() => setIsItemOpen(!isItemOpen)}
      >
        {name}
      </button>
      <ul className="dark:[&_a]:text-[#F8FAFA] text-base lg:text-lg list-none lista text-dsGray-900 absolute hidden [.open-list_&]:flex group-hover:flex top-full left-0 flex-col z-10 bg-dsGray-100">
        {dropdownItems.map((item) => (
          <li
            key={item.name}
            className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]"
          >
            <NavLink
              title={item.name}
              to={item.to}
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
              onClick={() => setIsItemOpen(false)}
            >
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </li>
  );
};