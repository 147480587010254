//@ts-check
import { IoAddCircleOutline, IoCheckmarkCircleOutline } from 'react-icons/io5';
import { CustomContainer } from '../../../../addons/utfpr_components/src/components/general';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

/** @type {React.FC<{onSubmit?:(login:string,password:string)=>any}>} */
export const LoginForm = (props) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  return (
    <CustomContainer className="" size="ensino-institucional" alignCenter>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          if (props.onSubmit) props.onSubmit(login, password);
        }}
        className="[&>label]:flex [&>label]:flex-col [&>label]:gap-4 dark:[&>label]:text-dsGray-100 [&_input]:p-2 [&_input]:rounded dark:[&_input]:bg-slate-50/20 [&_input]:bg-slate-500/5 focus-visible:[&_input]:outline-none w-full flex gap-4 flex-col p-4 shadow-xl shadow-dsGray-700 rounded-xl "
      >
        <h4 className="capitalize dark:text-dsGray-50">entrar</h4>
        <label>
          Nome do usuário
          <input
            placeholder="Nome do usuário"
            onChange={(ev) => {
              setLogin(ev.currentTarget.value);
            }}
            name="login"
            id="login"
            autoFocus
          ></input>
        </label>
        <label>
          Senha
          <input
            placeholder="Senha"
            id="password"
            type="password"
            autoComplete="current-password"
            name="password"
            onChange={(ev) => {
              setPassword(ev.currentTarget.value);
            }}
          ></input>
        </label>
        <div className="flex flex-row justify-end">
          <button type="reset" className="rotate-45">
            <IoAddCircleOutline className="text-6xl dark:text-red-800 text-red-300 dark:hover:text-red-500 hover:text-red-500 hover:text-pri [&_*]:stroke-[1.25rem] transition duration-300"></IoAddCircleOutline>
          </button>
          <button type="submit">
            <IoCheckmarkCircleOutline className="text-6xl dark:text-primary-800 dark:hover:text-primary-500 text-primary-200 hover:text-primary-500 [&_*]:stroke-[1.25rem] transition duration-300"></IoCheckmarkCircleOutline>
          </button>
        </div>
      </form>
    </CustomContainer>
  );
};
