//@ts-check
import React, { useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  EffectFade,
  Autoplay,
  Pagination,
} from 'swiper/core';
import Item from './Item';

//@ts-ignore
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';

import { isImageObjectBrowser } from '../../helpers/isImageObjectBrowser.js';

SwiperCore.use([Navigation, EffectFade, Autoplay, Pagination]);

const BannerContainer = ({ children }) => {
  return (
    <div className="!w-full !mx-auto overflow-hidden banner">
      <div
        className="w-full relative
        sm:[&_div.swiper-button-prev]:!w-24
        [&_div.swiper-button-prev]:!w-14
        sm:[&_div.swiper-button-next]:!w-24
        [&_div.swiper-button-next]:!w-14
        smoother-swiper"
      >
        {children}
      </div>
    </div>
  );
};

const BannerView = (props) => {
  /** @type {(url?:string)=>string} */
  const urlImageHandler = useCallback(
    (url) =>
      isInternalURL(url)
        ? `${flattenToAppURL(url)}/@@images/image`
        : `${url}/@@images/image`,
    [],
  );

  /** @type {(url?:string)=>string} */
  const urlHandler = useCallback(
    (url) => (isInternalURL(url) ? flattenToAppURL(url) : url),
    [],
  );
  //console.log('BannerView props data', props.data);
  const { data, isEditMode } = props;

  const { files, slides } = data;

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span className="${className} border border-black !bg-[#FFC800] shadow !w-[13px] !h-[13px]"></span>`;
    },
  };

  //console.log('props.token', props.token);
  //console.log('props.editable', props.editable);

  const isThereSlides = slides?.length; // undefined or number: 0, 1, 2, 3, ...
  const isThereFiles = files?.length;

  //console.log('isThereSlides isThereFiles', isThereSlides, isThereFiles);

  if (!isThereSlides && isThereFiles) {
    return (
      <BannerContainer>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          navigation={files.length > 1 ? true : false}
          loop={files.length > 1 ? true : false}
          effect={'fade'}
          autoplay={
            props.editable
              ? false
              : {
                  delay: 4000,
                  disableOnInteraction: true,
                }
          }
          style={{}}
          autoHeight={true}
          pagination={pagination}
        >
          {files.map((file, idx) => {
            const isImage = isImageObjectBrowser(file);

            //console.log('urlImageHandler(file.getURL)', urlImageHandler(file.getURL), isImage);
            //console.log('flattenToAppURL(file.getURL)', flattenToAppURL(file.getURL));
            //console.log('isInternalURL(file.getURL)', isInternalURL(file.getURL));

            const link = isImage
              ? urlHandler(data[`imageLink${idx}`])
              : urlHandler(file.getURL);

            let title = isImage ? data[`imageTitle${idx}`] : file.title;

            if (!title || data[`hide_title_${idx}`]) title = '';

            return (
              <SwiperSlide key={file.getURL + idx}>
                <Item
                  key={file.getURL + idx}
                  title={title}
                  img={urlImageHandler(file.getURL)}
                  link={link}
                  horizontalOffset={'center'}
                  verticalOffset={data[`imageVerticalOffset${idx}`]}
                  isEditMode={isEditMode}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </BannerContainer>
    );
  }

  if (isThereSlides) {
    //console.log('slides', slides)
    return (
      <BannerContainer>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          navigation={slides.length > 1 ? true : false}
          loop={slides.length > 1 ? true : false}
          effect={'fade'}
          autoplay={
            props.editable
              ? false
              : {
                  delay: 4000,
                  disableOnInteraction: true,
                }
          }
          style={{}}
          autoHeight={true}
          pagination={pagination}
        >
          {slides.map((slide, id) => {
            //console.log('urlImageHandler(slide.getURL)', urlImageHandler(slide.getURL), isImage);
            //console.log('flattenToAppURL(slide.getURL)', flattenToAppURL(slide.getURL));
            //console.log('isInternalURL(slide.getURL)', isInternalURL(slide.getURL));

            //console.log('slide', slide);

            let slideTitle = '';
            let slideUrl = '';
            const isThereObj = slide.newsObj?.length;
            //console.log('isThereObj', isThereObj)
            if (isThereObj) {
              const { title, getURL } = slide.newsObj[0];
              slideTitle = title;
              slideUrl = getURL;
            }

            const customTitle = slide.customTitle
              ? slide.customTitleTxt
              : slideTitle || 'Escolha uma notícia';

            //console.log('customTitle', customTitle)

            const isThereCustomImgObj = slide.customImgObj?.length;

            //console.log('isThereCustomImgObj', isThereCustomImgObj)

            const isThereDefaultImageOnObj =
              isThereObj && slide.newsObj[0]['image_scales']?.image?.length > 0;

            //console.log('isThereDefaultImageOnObj', isThereDefaultImageOnObj)

            function bannerImg() {
              if (slide.customImg && isThereCustomImgObj) {
                return urlImageHandler(slide.customImgObj[0].getURL);
              }

              if (isThereDefaultImageOnObj) {
                return urlImageHandler(slide.newsObj[0].getURL);
              }

              return undefined;
            }

            //console.log('bannerImg()', bannerImg())

            const redirectLink = slide.imageLink || urlHandler(slideUrl);

            //console.log('redirectLink', redirectLink)

            return (
              <SwiperSlide key={id}>
                <Item
                  key={slide['@id']}
                  title={customTitle}
                  img={bannerImg()}
                  link={redirectLink}
                  horizontalOffset={slide.horizontalOffset}
                  verticalOffset={slide.verticalOffset}
                  hideModal={slide.hideModal}
                  isEditMode={isEditMode}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </BannerContainer>
    );
  }

  return (
    <div className="flex h-[450px] justify-center items-center">
      Sem slides para mostrar
    </div>
  );
};

export default BannerView;
