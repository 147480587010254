export const itemSchema = (props) => {
  return {
    title: 'Botão',
    addItem: '01',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'outline', 'newTab', 'link'],
      },
    ],

    properties: {
      title: {
        text: 'Texto do botão:',
      },
      newTab: {
        title: 'Abrir em uma nova aba',
        type: 'boolean',
      },
      link: {
        title: 'Link',
        mode: 'link',
      },
      outline: {
        title: 'Botão com borda',
        type: 'boolean',
      },
    },
    required: ['title', 'link'],
  };
};

export const geralSchema = (props) => ({
  title: 'Botões',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['container', 'align', 'buttons'],
    },
  ],

  properties: {
    buttons: {
      widget: 'object_list',
      title: 'Adicionar botão',
      schema: itemSchema,
    },
    align: {
      widget: 'align',
      title: 'Alinhamento dos botões:',
      actions: ['left', 'right', 'center'],
      default: 'left'
    },
    container: {
      title: 'Escolha o container para o componente:',
      widget: 'select',
      choices: [
        ['1', 'Tela inteira'],
        ['2', 'Centralizado'],
        ['3', 'Offset'],
      ]
    },
  },
  required: ['align', 'container'],
});
