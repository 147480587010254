const NumberIndicative = (props)=>{
    return(
        <>
            <div className='dark:bg-[#001C3A] flex w-full lg:w-[385px] h-[248px] flex-col rounded-[10px] shadow-lg bg-[#FFFFFF] justify-center item-center'>
                <h2 className="flex flex-row justify-center text-[57px] dark:text-white ">{props.value}</h2>
                <h3 className="subtitleUtfprNumbers flex flex-row justify-center text-[22px] dark:text-white ">{props.title}</h3>
                <p className="flex flex-row justify-center text-[24px] !m-0 dark:text-white ">{props.desc}</p>
            </div>
        </>
    )
}

export default NumberIndicative;