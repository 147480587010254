import React from 'react';
import { Segment, Form } from 'semantic-ui-react';
import { BlockDataForm } from '@plone/volto/components';
import {
  geralSchema,
  cartaoSchema,
  pesquisaSchema,
  iniciacaoCientificaSchema,
  cursoSchema,
  especializacaoSchema,
  gSchema,
  posSchema,
} from './Schema';

const PostItData = (props) => {
  const { onChangeBlock } = props;

  const schema =
    props.data.layout === 'Cartão'
      ? cartaoSchema({ ...props })
      : props.data.layout === 'Campus'
      ? gSchema({ ...props })
      : props.data.layout === 'Curso'
      ? cursoSchema({ ...props })
      : props.data.layout === 'Iniciação Científica'
      ? iniciacaoCientificaSchema({ ...props })
      : props.data.layout === 'Pesquisa'
      ? pesquisaSchema({ ...props })
      : props.data.layout === 'Pos-graduação'
      ? posSchema({ ...props })
      : props.data.layout === 'Especialização'
      ? especializacaoSchema({ ...props })
      : geralSchema({ ...props });

  return (
    <Segment.Group raised>
      <div>
        <Form>
          <BlockDataForm
            schema={schema}
            title={schema.title}
            onChangeField={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                [id]: value,
              });
            }}
            formData={props.data}
            block={props.block}
            onChangeBlock={onChangeBlock}
          />
        </Form>
      </div>
    </Segment.Group>
  );
};
export default PostItData;