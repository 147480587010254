import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { IconArrowRight } from 'addons/utfpr_components/src/assets/icons/buttons/IconArrowRight';
import { getFullDate } from 'helpers/Utils/Utils';
import React from 'react';
import { CustomContainer } from 'addons/utfpr_components/src/components/general';
import { highlightCities } from '../../../../addons/utfpr_components/src/helpers/city';

const ScheduleTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  const urlHandler = (url) => (isInternalURL(url) ? flattenToAppURL(url) : url);

  function openLink(link) {
    const url = urlHandler(link);
    window.location.href = url;
  }

  return (
    <CustomContainer
      className="flex flex-col gap-3 mx-auto items-center pb-5 flex-grow"
      size="pesquisa-extensao"
    >
      {items.map((item) => (
        <div
          key={item['@id']}
          className="w-full  flex flex-col cursor-pointer group dark:bg-[#001B3D]"
          onClick={() => {
            openLink(item.getURL);
          }}
        >
          <div className="w-full p-4 group-hover:bg-[#A9C7FF] dark:group-hover:bg-[#00468C] transition">
            <div className="flex gap-4 items-center text-[16px] font-bold">
              {item.start && (
                <div className="text-[#2E3132] group-hover:text-[#001B3D] dark:text-[#ffffff] dark:group-hover:text-[#ffffff]">
                  {getFullDate(item.start)}
                </div>
              )}
              <IconArrowRight
                className="dark:[&_path]:stroke-white group-hover:[&_path]:stroke-[#001B3D] dark:group-hover:[&_path]:stroke-white"
                size="22px"
                color="#2E3132"
              />
              {item.end && (
                <div className="text-[#2E3132] group-hover:text-[#001b3d] dark:text-[#ffffff] dark:group-hover:text-[#ffffff]">
                  {getFullDate(item.end)}
                </div>
              )}
            </div>
          </div>
          <div className="w-full min-w-[200px] border-l-[6px] border-[#FCBC00] shadow p-6 py-4 transition">
            <div className="text-black text-[15px] min-w-[200px] dark:text-[#F8FAFA] font-semibold">
              {highlightCities(item.title)}
            </div>
          </div>
        </div>
      ))}
    </CustomContainer>
  );
};

export default ScheduleTemplate;
