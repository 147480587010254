//@ts-check
import React, { useState, useEffect } from 'react';
import { IconRow } from '../../../assets/icons/IconRow';
import { IconColumn } from '../../../assets/icons/IconColumn';
import { IconDoubleArrows } from '../../../assets/icons/IconDoubleArrows';
import { SwiperSlide } from 'swiper/react';
import CarouselContainer from '../../CarouselContainer/CarouselContainer';
import MainNewsItemRow from './Items/Row';
import MainNewsItemColumn from './Items/Column';
import { formatDate } from '../../../../../../helpers/Utils/Utils';
//@ts-ignore
import { searchContent } from '@plone/volto/actions';
import { useDispatch } from 'react-redux';
import { CustomContainer } from '../../general';
//@ts-ignore
import { urlImageHandler } from '../../../helpers/urlImageHandle';
import { urlHandler } from '../../../helpers/urlHandler';

/**
 * @typedef {object} FormatedCardData
 * @prop {string} id
 * @prop {string} img
 * @prop {string} title
 * @prop {string[]} tags
 * @prop {string} description
 * @prop {string} link
 * @prop {string} creationDate
 *
 * @typedef {object} TNoticia
 * @prop {string | undefined} title
 * @prop {string | undefined} Title
 * @prop {string | undefined} description
 * @prop {string | undefined} created
 * @prop {string[]} Subject
 * @prop {string | undefined} getPath
 *
 * @typedef {object} SearchResult
 * @prop {TNoticia[]} items
 *
 * @typedef {object} NewsItemFile
 * @prop {boolean} isFolder
 * @prop {[TNoticia]} noticia
 *
 * @typedef {NewsItemFile} DataFile
 *
 * @typedef {React.Dispatch<React.SetStateAction<FormatedCardData[]>>} SetFormatedCardDatas
 *
 *
 * @param {{dataFiles:DataFile[],editable:boolean,colModeLimit:number,token:string|undefined,showImages:boolean}} param
 * @returns
 */
const NewsTabContent = ({
  dataFiles,
  editable,
  colModeLimit,
  token,
  showImages,
}) => {
  const newsFolders = getNewsFolders(dataFiles);
  const newsItems = getNewsItems(dataFiles);
  const colLimit = colModeLimit || 0;

  /**@type {[FormatedCardData[],SetFormatedCardDatas]} */
  //@ts-ignore
  const [news, setNews] = useState([]);
  /**@type {[FormatedCardData[],SetFormatedCardDatas]} */
  //@ts-ignore
  const [dynamicNews, setDynamicNews] = useState(
    /** @type {FormatedCardData[]} */ [],
  );
  const [layoutRow, setLayoutRow] = useState(true);
  const [moreRecent, setMoreRecent] = useState(true);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const savedLayoutRow = localStorage.getItem('layoutRow');
    const isRow = savedLayoutRow === 'true' || savedLayoutRow === null; 
    setLayoutRow(isRow);
  }, []);
  
  useEffect(() => {
    const savedLayoutRow = localStorage.getItem('layoutRow');
    if (savedLayoutRow !== layoutRow.toString()) {
      localStorage.setItem('layoutRow', layoutRow.toString());
    }
  }, [layoutRow]);
  
  useEffect(() => {
    newsItems.forEach((newsItem, index) => {
      if (
        newsItem.noticia &&
        newsItem.noticia[0] &&
        newsItem.noticia[0]['@id']
      ) {
        news.push(formatCardData(newsItem.noticia[0]));
      }
    });

    if (!newsFolders.length) {
      setLoading(false);
    }

    setNews([...news]);
  }, []);

  useEffect(() => {
    let index = 0;
    /**
     * @param {DataFile} folder
     */
    async function getFolderContent(folder) {
      index++;

      if (!folder) return undefined;
      if (!folder.noticia) return undefined;
      if (!folder.noticia[0].getPath) return undefined;

      try {
        /**@type {SearchResult} */
        const folderContent = await dispatch(
          searchContent(
            folder.noticia[0].getPath.split('/Plone')[1],
            {
              b_start: 0,
              b_size: 15,
              fullobjects: true,
              portal_type: ['News Item', 'Notícia'],
              sort_on: 'effective',
              sort_order: moreRecent ? 'reverse' : undefined,
            },
            `resultado_${index}`, // Aqui usamos um nome dinâmico para a subrequest
          ),
        );

        /** @type {FormatedCardData[]} */
        const formatedCardDataWithoutHat = [];

        for (let i = 0; i < folderContent.items.length; i++) {
          const cardData = folderContent.items[i];

          formatedCardDataWithoutHat.push(formatCardData(cardData));
        }

        dynamicNews.push(...formatedCardDataWithoutHat);

        setDynamicNews([...dynamicNews]);
      } catch {}
    }
    setLoading(true);
    setDynamicNews([]);

    const searchContentPromisses = [];
    for (let i = 0; i < newsFolders.length; i++)
      searchContentPromisses.push(getFolderContent(newsFolders[i]));

    Promise.all(searchContentPromisses).then(() => {
      setLoading(false);
    });
  }, [moreRecent]);

  return (
    <div className="w-full flex flex-col gap-5">
      <CustomContainer
        size="ensino-institucional"
        alignCenter={true}
        className="flex justify-start items-center gap-3 mb-2 dark:text-[#F8FAFA] text-[#2E3132]"
      >
        <div
          role="button"
          tabIndex={0}
          className="cursor-pointer"
          onClick={() => {
            setLayoutRow(!layoutRow);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setLayoutRow(!layoutRow);
            }
          }}
        >
          {layoutRow ? (
            <IconColumn className="stroke-[#2E3132] hover:stroke-[#FFBE00] dark:hover:stroke-[#FFBE00] dark:stroke-[#F8FAFA]" />
          ) : (
            <IconRow className="stroke-[#2E3132] hover:stroke-[#FFBE00] dark:hover:stroke-[#FFBE00] dark:stroke-[#F8FAFA]" />
          )}
        </div>
        |
        <div
          role="button"
          tabIndex={0}
          className="cursor-pointer"
          onClick={() => {
            setMoreRecent(!moreRecent);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setMoreRecent(!moreRecent);
            }
          }}
        >
          <IconDoubleArrows className="stroke-[#2E3132] hover:stroke-[#FFBE00] dark:hover:stroke-[#FFBE00] dark:stroke-[#F8FAFA]" />
        </div>
        <div className="text-[#2E3132] dark:text-[#F8FAFA]">
          {moreRecent ? 'Mais recentes' : 'Mais antigos'}
        </div>
      </CustomContainer>

      <div
        className={`w-full relative overflow-hidden [&_div.swiper-button-prev]:!w-16 [&_div.swiper-button-next]:!w-16`}
      >
        {layoutRow ? (
          <>
            {loading /* for loading tests you can change this to "true", so its always loading */ ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-4">
                <div className="h-[500px] w-full bg-gray-500/50 animate-pulse"></div>
                <div className="h-[500px] w-full bg-gray-500/50 animate-pulse hidden md:block"></div>
                <div className="h-[500px] w-full bg-gray-500/50 animate-pulse hidden lg:block"></div>
                <div className="h-[500px] w-full bg-gray-500/50 animate-pulse hidden lg:block"></div>
                <div className="h-[500px] w-full bg-gray-500/50 animate-pulse hidden 2xl:block"></div>
              </div>
            ) : (
              <CarouselContainer>
                {[...news, ...dynamicNews].map((new_data, index) => (
                  <SwiperSlide
                    className={
                      showImages
                        ? 'swiper-slide-newsCard'
                        : 'swiper-slide-newsCard no-image'
                    }
                    key={index}
                  >
                    <MainNewsItemRow
                      img={new_data.img}
                      tags={new_data.tags}
                      title={new_data.title}
                      youtube={false}
                      editable={editable}
                      description={new_data.description}
                      link={new_data.link}
                      date={new_data.creationDate}
                      showImage={showImages}
                      youtubeState={undefined}
                    />
                  </SwiperSlide>
                ))}
              </CarouselContainer>
            )}
          </>
        ) : (
          <CustomContainer
            size="ensino-institucional"
            alignCenter={true}
            className="mx-auto flex flex-col gap-5"
          >
            {renderColumnNewsItems(
              [...news, ...dynamicNews],
              colLimit,
              editable,
              showImages,
            )}
          </CustomContainer>
        )}
      </div>
    </div>
  );
};

/**
 * @param {NewsItemFile[]} dataFiles
 * @returns {NewsItemFile[]}
 */
function getNewsItems(dataFiles) {
  if (!isDataFile(dataFiles)) return [];

  const newsItems = dataFiles.filter((dataFile) => !dataFile.isFolder);

  return newsItems;
}

/**
 * @param {NewsItemFile[]} dataFiles
 * @returns {NewsItemFile[]}
 */
function getNewsFolders(dataFiles) {
  if (!isDataFile(dataFiles)) return [];

  const newsItems = dataFiles.filter((dataFile) => dataFile.isFolder);

  return newsItems;
}
/**
 *  @param {NewsItemFile[]} fileData
 */
function isDataFile(fileData) {
  return fileData && fileData.length && fileData[0].noticia;
}

/**
 *
 * @param {TNoticia} cardData
 * @returns {FormatedCardData}
 */
function formatCardData(cardData) {
  if (!cardData['@id'])
    return {
      id: '',
      img: '',
      title: '',
      tags: [],
      description: '',
      link: '',
      creationDate: formatDate(0, 'dd/MM/yyyy HHhmm'),
    };
  const cardId = cardData['@id'];

  return {
    id: cardId,
    img: urlImageHandler(cardId),
    title: cardData.title || cardData.Title || '',
    tags: cardData.Subject || [],
    description: cardData.description || '',
    link: urlHandler(cardId),
    creationDate: formatDate(cardData.created, 'dd/MM/yyyy HHhmm'),
  };
}

/**
 * @param {FormatedCardData[]} newsItems
 * @param {number} limit
 * @param {boolean} editable
 * @param {boolean} showImages
 * @returns {React.ReactNode}
 */
function renderColumnNewsItems(newsItems, limit, editable, showImages) {
  const render = [];

  // if the limit equals 0, there is no limit
  limit = limit ? limit : newsItems.length;

  limit = Math.min(limit, newsItems.length);

  for (let i = 0; i < newsItems.length && i < limit; i++)
    render.push(
      <MainNewsItemColumn
        editable={editable}
        key={i}
        img={newsItems[i].img}
        tags={newsItems[i].tags}
        title={newsItems[i].title}
        description={newsItems[i].description}
        link={newsItems[i].link}
        date={newsItems[i].creationDate}
        showImage={showImages}
      />,
    );

  return render;
}

export default NewsTabContent;
