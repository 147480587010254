import React, { useState, useEffect } from "react";
import {
  EditorState,
  Modifier,
  RichUtils,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { IoArrowForward, IoClose } from "react-icons/io5";
import { normalizeURL, stripProtocol } from './urlUtils'; 

const EditorToolbar = ({ index, editorState, onChange, savedContent }) => {
  const [linkURL, setLinkURL] = useState("");
  const [isLinkInputVisible, setIsLinkInputVisible] = useState(false);

  useEffect(() => {
    if (savedContent) {
      try {
        const contentState = convertFromRaw(savedContent);
        const restoredEditorState = EditorState.createWithContent(contentState);
        onChange(index, restoredEditorState);
      } catch (error) {
        console.error("erro ao carregar conteúdo: ", error);
      }
    }
  }, [savedContent, index, onChange]);

  const toggleStyle = (style) => {
    onChange(index, RichUtils.toggleInlineStyle(editorState, style));
  };

  const showLinkInput = () => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = selection.getStartKey();
      const contentBlock = contentState.getBlockForKey(startKey);
      const entityKey = contentBlock.getEntityAt(selection.getStartOffset());
      if (entityKey) {
        const entity = contentState.getEntity(entityKey);
        if (entity.getType() === "LINK") setLinkURL(stripProtocol(entity.getData().url));
      }
    }
    setIsLinkInputVisible(true);
  };

  const addLink = () => {
    if (linkURL.trim()) {
      const normalizedURL = normalizeURL(linkURL.trim());
      const selection = editorState.getSelection();
      if (!selection.isCollapsed()) {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("LINK", "MUTABLE", {
          url: normalizedURL,
        });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const contentWithLink = Modifier.applyEntity(
          contentStateWithEntity,
          selection,
          entityKey
        );
        onChange(index, EditorState.push(editorState, contentWithLink, "apply-entity"));
      }
    }
    closeLinkInput();
  };

  const removeLink = () => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const contentWithoutEntity = Modifier.applyEntity(contentState, selection, null);
      onChange(index, EditorState.push(editorState, contentWithoutEntity, "apply-entity"));
    }
    closeLinkInput();
  };

  const closeLinkInput = () => {
    setLinkURL("");
    setIsLinkInputVisible(false);
  };

  return (
    <div className="flex flex-col gap-2 mb-2">
      <div className="flex gap-2">
        {["BOLD", "UNDERLINE", "ITALIC"].map((style) => (
          <button
            key={style}
            className="px-2 py-1 bg-gray-200 rounded dark:bg-gray-700"
            onClick={() => toggleStyle(style)}
          >
            {style === "BOLD" && <strong>B</strong>}
            {style === "UNDERLINE" && <u>U</u>}
            {style === "ITALIC" && <em>I</em>}
          </button>
        ))}
        <button
          className="px-2 py-1 bg-gray-200 rounded dark:bg-gray-700"
          onClick={showLinkInput}
        >
          Link
        </button>
      </div>

      {isLinkInputVisible && (
        <div className="flex items-center gap-2 mt-2">
          <input
            type="text"
            className="p-2 border border-gray-300 rounded flex-1 dark:border-gray-600 text-[#0D1117]"
            placeholder="Digite o link"
            value={linkURL}
            onChange={(e) => setLinkURL(e.target.value)}
          />
          <button className="px-2 py-1 text-red-500" onClick={removeLink}>
            <IoClose size={16} />
          </button>
          <button className="px-2 py-1" onClick={addLink}>
            <IoArrowForward size={16} color="#005DB7" />
          </button>
        </div>
      )}
    </div>
  );
};

export default EditorToolbar;