export const normalizeURL = (url) => {
  if (url.startsWith('/')) {
    return `${window.location.origin}${url}`;
  }
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`;
  }
  return url;
};

export const stripProtocol = (url) => url.replace(/^https?:\/\//, "");