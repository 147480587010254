import { IconDiagonalTopRightArrow } from '../../../assets/icons/buttons/IconDiagonalTopRightArrow';

const CampusLayout = (props) => {
  //Não mude a ordem nunca
  const Campus = [
    'Apucarana',
    'Campo Mourão',
    'Cornélio Procópio',
    'Curitiba',
    'Dois Vizinhos',
    'Francisco Beltrão',
    'Guarapuava',
    'Londrina',
    'Medianeira',
    'Pato Branco',
    'Ponta Grossa',
    'Santa Helena',
    'Toledo',
  ];
  return (
    <div className="mx-auto items-stretch w-full p-4 gap-[24px] grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center">
      {Campus.map((camp, index) => {
        return (
          <CampusItem
            campus={camp}
            index={index * 4 + 1}
            data={props}
            editable={props.editable}
            onChangeBlock={props.onChangeBlock}
            block={props.block}
          ></CampusItem>
        );
      })}
    </div>
  );
};
export default CampusLayout;

const CampusItem = ({
  data,
  index,
  campus,
  editable,
  block,
  onChangeBlock,
}) => {
  return (
    <div className=" w-full p-6 bg-[#B9DDFF] space-y-4 flex flex-col justify-between items-start px-6">
      <div className="w-full space-y-4 flex flex-1 flex-col justify-between items-start">
        <h4 className="text-left font-bold text-[24px]" translate="no">{campus}</h4>
        <TextLink
          data={data}
          index={index}
          editable={editable}
          block={block}
          onChangeBlock={onChangeBlock}
        ></TextLink>
        <TextLink
          data={data}
          index={index + 1}
          editable={editable}
          block={block}
          onChangeBlock={onChangeBlock}
        ></TextLink>
        <TextLink
          data={data}
          index={index + 2}
          editable={editable}
          block={block}
          onChangeBlock={onChangeBlock}
        ></TextLink>
        {editable ? (
          <div className="w-full flex flex-col gap-4 border-b border-[#fff7] last:border-b-0 pb-4">
            <div className="flex justify-center">
              <input
                onChange={(ev) => {
                  onChangeBlock(block, {
                    ...data,
                    [`title${index + 3}`]: ev.target.value
                      ? ev.target.value
                      : '',
                  });
                }}
                value={data[`title${index + 3}`]}
                placeholder={`chip`}
                className={
                  'bg-[#fff7] text-[#0169CD] text-[14px] font-semibold  group-hover:text-[#005DB7] w-full'
                }
              ></input>
              <IconDiagonalTopRightArrow
                size="16"
                color="#0169CD"
                className=" group-hover:[&_path]:stroke-[#005DB7] min-w-max"
              />
            </div>
            <input
              type="url"
              className={'bg-[#fff7] font-normal text-left text-[16px] w-full'}
              placeholder={`link do chip`}
              value={data[`href${index + 3}`]}
              onChange={(ev) => {
                onChangeBlock(block, {
                  ...data,
                  [`href${index + 3}`]: ev.target.value ? ev.target.value : '',
                });
                console.debug(index);
              }}
            ></input>
          </div>
        ) : (
          <a
            href={data[`href${index + 3}`] || ''}
            onClick={(ev) => {
              if (!data[`href${index + 3}`]) ev.preventDefault();
            }}
            className="ml-auto hover:text-[#005DB7] font-medium  cursor-pointer"
          >
            <div className="flex justify-center items-center">
              <p className=" text-[#0169CD] text-[14px] font-semibold  group-hover:text-[#005DB7]">
                {data[`title${index + 3}`]}
              </p>
              <IconDiagonalTopRightArrow
                size="16"
                color="#0169CD"
                className=" group-hover:[&_path]:stroke-[#005DB7] min-w-max"
              />
            </div>
          </a>
        )}
      </div>
    </div>
  );
};

const TextLink = ({ editable, block, onChangeBlock, index, data }) => {
  return editable ? (
    <div className="w-full flex flex-col gap-4 border-b border-[#fff7] last:border-b-0 pb-4 ">
      <input
        onChange={(ev) => {
          onChangeBlock(block, {
            ...data,
            [`title${index}`]: ev.target.value ? ev.target.value : '',
          });
        }}
        value={data[`title${index}`]}
        placeholder={`texto ${index % 4}`}
        className={'bg-[#fff7] font-normal text-left text-[16px] '}
      ></input>
      <input
        type="url"
        className={'bg-[#fff7] font-normal text-left text-[16px] '}
        placeholder={`link ${index % 4}`}
        value={data[`href${index}`]}
        onChange={(ev) => {
          onChangeBlock(block, {
            ...data,
            [`href${index}`]: ev.target.value ? ev.target.value : '',
          });
        }}
      ></input>
    </div>
  ) : (
    <a
      href={data[`href${index}`]}
      onClick={(ev) => {
        if (!data[`href${index}`]) ev.preventDefault();
      }}
    >
      <p className="text-left font-normal hover:text-[#005DB7] text-[16px] cursor-pointer">
        {data[`title${index}`]}
      </p>
    </a>
  );
};
