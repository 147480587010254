import React from 'react';
import View from './View.jsx';
import { Segment, Form } from 'semantic-ui-react';
import { BlockDataForm, SidebarPortal } from '@plone/volto/components';
import { geralSchema } from './schema';

const Edit = (props) => {
  const { onChangeBlock, schemaEnhancer, selected } = props;

  const schema = schemaEnhancer
    ? schemaEnhancer(geralSchema({ ...props }), props)
    : geralSchema({ ...props });

  return (
    <>
      <View {...props} isEditMode={true} />
      <SidebarPortal selected={selected}>
        <Segment.Group raised>
          <div>
            <Form>
              <BlockDataForm
                schema={schema}
                title={schema.title}
                onChangeField={(id, value) => {
                  props.onChangeBlock(props.block, {
                    ...props.data,
                    [id]: value,
                  });
                }}
                formData={props.data}
                block={props.block}
                onChangeBlock={onChangeBlock}
              />
            </Form>
          </div>
        </Segment.Group>
      </SidebarPortal>
    </>
  );
};

export default Edit;