//@ts-check
import React from 'react';

import { useState, useContext } from 'react';
//@ts-ignore
import ContainerEdit from '@plone/volto/components/manage/Blocks/Container/Edit';
import { TwoColumnsPropertiesContext } from '../TwoColumns/twoColumnsPropertiesContext';
//@ts-ignore
import config from '@plone/volto/registry';

export const SingleColumnEdit = (props) => {
  const [selectedBlock, setSelectedBlock] = useState(null);
  const internalProps = { ...props };
  const { blocksConfig } = config['_data'].blocks;
  if (blocksConfig) internalProps.blocksConfig = blocksConfig;
  return (
    <div
      className="w-full min-h-[20rem]  [&>div>fieldset>div]:flex [&>div>fieldset>div]:flex-col [&>div>fieldset>div>div>div>.remove-block-button]:!inline-block"
      onClick={(e) => {
        if (!e) setSelectedBlock(null);
      }}
      role="presentation"
    >
      <h3>adicione seus blocos aqui</h3>
      <ContainerEdit
        {...internalProps}
        selectedBlock={selectedBlock}
        setSelectedBlock={setSelectedBlock}
        direction="horizontal"
      />
    </div>
  );
};
