const MoneyIndicative = (props)=>{
    return(
        <>
            <div className="flex w-full lg:w-[485px] h-[160px] flex-row rounded-[10px] shadow-lg bg-[#FFFFFF] justify-center items-center gap-[27px] py-[42px] dark:bg-[#001C3A]">
                <div className="font-bold text-[57px] dark:text-white">
                    R${props.value}
                </div>
                <div className="flex flex-col justify-start items-start">
                    <div className="text-[22px] font-bold dark:text-white">
                        {props.title}
                    </div>
                    <p className="!m-0 text-[16px] w-[122px] dark:text-white">
                        {props.desc}
                    </p>
                </div>
            </div>
        </>
    )
}

export default MoneyIndicative;