import PostItPesquisaLayout from '../../../../addons/utfpr_components/src/components/PostIt/layouts/Pesquisa';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { highlightCities } from '../../../../addons/utfpr_components/src/helpers/city';

const PostItSeartch = ({ items, linkTitle, linkHref, isEditMode }) => {
  const urlHandler = (url) => (isInternalURL(url) ? flattenToAppURL(url) : url);

  function openLink(link) {
    window.open(urlHandler(link), '_blank');
  }

  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-[24px] pb-5 justify-start">
      {items.map((item) => (
        <div
          key={item['@id']}
          className="cursor-pointer hover:shadow-lg bg-[#B9DDFF] hover:bg-[#a5d2fc] transition"
          onClick={() => openLink(item.getURL)}
        >
          <PostItPesquisaLayout
            title={highlightCities(item.title)}
            text={item.Autores}
            fomento={<p>CNPQ</p>}
            labels={
              item.Subject?.length > 0 &&
              item.Subject.map((subject, idx) => ({
                text: highlightCities(
                  subject
                    .split(' ')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')
                ),
              }))
            }
          />
        </div>
      ))}
    </div>
  );
};

export default PostItSeartch;