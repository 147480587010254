/**
 * View title/description block.
 * @module volto-slate/blocks/Title/TitleBlockView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { CustomContainer } from 'addons/utfpr_components/src/components/general';
import { highlightCities } from 'addons/utfpr_components/src/helpers/city';

/**
 * View title block component.
 * @class View
 * @extends Component
 */
const TitleBlockView = ({ properties, metadata }) => {
  const description = (metadata || properties)['description'] || '';
  return (
    <CustomContainer
      size="ensino-institucional"
      alignCenter={true}
      className=" dark:text-[#F8FAFA]"
    >
      <div
        className="font-dsSubtitle text-black dark:text-[#F8FAFA]"
      >
        {highlightCities(description)}
      </div>
    </CustomContainer>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
TitleBlockView.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
  metadata: PropTypes.objectOf(PropTypes.any),
};

export default TitleBlockView;
