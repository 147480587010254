import React from 'react';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { CustomContainer } from '../general';

const View = (props) => {
  const urlImageHandler = (url) =>
    isInternalURL(url) ? `${flattenToAppURL(url)}/@@images/image` : url;
  const urlHandler = (url) => (isInternalURL(url) ? flattenToAppURL(url) : url);
  const { data, isEditMode } = props;
  const content = data.brands;

  if (!content || content.length === 0) {
    return isEditMode ? (
      <div className="text-center">Nenhuma marca disponível</div>
    ) : null;
  }

  return (
    <CustomContainer
      className="flex flex-wrap justify-center items-start gap-[22px] self-start mx-auto"
      alignCenter={true}
      size="pesquisa-extensao"
    >
      {content.map((item, index) => {
        const icon = item.icon ? item.icon[0]['@id'] : '';
        const title = item.title;
        const link = item.link;
        return (
          <a
            href={urlHandler(link)}
            key={index}
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center items-center h-[70px] rounded-md hover:shadow-lg p-1 bg-white"
          >
            <img
              src={urlImageHandler(icon)}
              alt={title}
              className="w-[80px] h-auto"
            />
          </a>
        );
      })}
    </CustomContainer>
  );
};

export default View;