export const geralSchema = (props) => ({

  title: 'Customização do divider',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['container'],
    },
  ],

  properties: {
    container: {
      title: 'Escolha o container para o componente',
      widget: 'select',
      choices: [
        ['1', 'Tela inteira'],
        ['2', 'Centralizado'],
        ['3', 'Offset'],
      ]
    },
  },
  required: ['container'],

});