import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { useSelector, shallowEqual } from 'react-redux';

const Anontools = ({ user }) => {
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const content = useSelector((state) => state.content.data, shallowEqual);
  const history = useHistory();

  const { settings } = config;

  const handleLoginClick = () => {
    if (user === 'estudante') {
      window.location.href = 'https://sistemas2.utfpr.edu.br/login';
    } else {
      const loginPath = `${
        content?.['@id'] ? `${flattenToAppURL(content['@id'])}` : ''
      }/login-menu`;
      history.push(loginPath);
    }
  };

  return (
    !token && (
      <div className="flex gap-2">
        <button
          className="bg-white rounded-md px-3 py-2 font-dsButton text-dsBlue-600 hover:text-yellow-500 whitespace-nowrap"
          onClick={handleLoginClick}
        >
          <FormattedMessage id="Log in" defaultMessage="Log in" />
        </button>
        {settings.showSelfRegistration && (
          <button
            className="bg-white rounded-md px-3 py-2 font-dsButton text-dsBlue-600 hover:text-yellow-500 whitespace-nowrap"
            onClick={() => history.push('/register')}
          >
            <FormattedMessage id="Register" defaultMessage="Register" />
          </button>
        )}
      </div>
    )
  );
};

export default Anontools;

Anontools.propTypes = {
  user: PropTypes.string,
  content: PropTypes.shape({
    '@id': PropTypes.string,
  }),
};

Anontools.defaultProps = {
  user: null,
  content: {
    '@id': null,
  },
};
