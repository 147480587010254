import React from 'react';
import { SidebarPortal } from '@plone/volto/components';
import BannerView from './View.jsx';
import BannerData from './Data.jsx';

const BannerEdit = (props) => {
  const { selected } = props;

  return (
    <>
      <BannerView {...props} isEditMode={true} />
      <SidebarPortal selected={selected}>
        <BannerData {...props} />
      </SidebarPortal>
    </>
  );
};

export default BannerEdit;
