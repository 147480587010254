export const ImageSchema = () => {
  return {
    title: 'Card',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['src'],
      },
    ],
    properties: {
      src: {
        title: "Selecione uma imagem",
        widget: "file",
      },
    },
    required: ['src'],
  };
};

export const geralSchema = (props) => {
  return {
    title: "Personalizar",
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['images'],
      },
    ],
    properties: {
      images: {
        title: "Card de imagem",
        widget: "object_list",
        schema: ImageSchema(),
        maxItems: 3, 
      },
    },
    required: ['images'],
  };
};