const languages = [
  { label: 'PT', value: 'pt' },
  { label: 'EN', value: 'en' },
  { label: 'ES', value: 'es' },
  { label: 'FR', value: 'fr' },
];

export const LanguageSelector = ({ onChange, value }) => {
  return (
    <select
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
      className="px-3 py-2 rounded-md w-20 font-dsButton"
    >
      {languages.map((it) => (
        <option translate="no" value={it.value} key={it.value} className="">
          {it.label}
        </option>
      ))}
    </select>
  );
};
