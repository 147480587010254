import { GridButtonsView, GroupButtonsView, GroupDescriptionView, FlexButtonsView } from './Views';

const ButtonsView = (props) => {
  const { data, isEditMode } = props;

  const ReturnView = () => {
    if (!data.buttons || data.buttons.length === 0) {
      return isEditMode ? <div className='text-center'>Nenhum botão disponível</div> : null;
    }

    let view;
    switch (data.layout) {
      case '0':
        view = <GridButtonsView {...props} />;
        break;
      case '1':
        view = <GroupButtonsView {...props} />;
        break;
      case '2':
        view = <GroupDescriptionView {...props} />;
        break;
      case '3':
        view = <FlexButtonsView {...props} />;
        break;
      default:
        view = <GridButtonsView {...props} />;
        break;
    }
    return view;
  };

  return ReturnView();
};

export default ButtonsView;