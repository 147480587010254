import { Layouts } from './layouts';

export const especializacaoSchema = () => ({
  title: 'Post it',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'color',
        'layout',
        'campus',
        'duracao',
        'turmas',
        'situacao',
        'periodo',
        'aulas',
        'label',
      ],
    },
  ],
  properties: {
    color: {
      title: 'Escolha a cor',
      type: 'select_widget',
      choices: [
        ['#B9DDFF', 'Azul'],
        ['#FFEBAF', 'Amarelo'],
        ['#C5EBC0', 'Verde'],
        ['#FFCCD1', 'Rosa'],
        ['#C2A6E4', 'Roxo'],
      ],
    },
    layout: {
      title: 'Escolha o layout',
      type: 'select_widget',
      choices: Layouts,
    },
    campus: {
      title: 'Campus',
      widget: 'text',
    },
    duracao: {
      title: 'Duração',
      widget: 'text',
    },
    turmas: {
      title: 'Abertura de turmas',
      widget: 'text',
    },
    situacao: {
      title: 'Situação das inscrições',
      widget: 'text',
    },
    periodo: {
      title: 'Período de inscrição',
      widget: 'text',
    },
    aulas: {
      title: 'Início das aulas',
      widget: 'text',
    },
    label: {
      title: 'Adicionar rótulo',
      widget: 'object_list',
      schema: labelSchema,
    },
  },
  required: [
    'color',
    'layout',
    'campus',
    'duracao',
    'turmas',
    'situacao',
    'periodo',
    'aulas',
    'label',
  ],
});

export const labelSchema = () => ({
  title: 'Rótulo customizável',
  addMessage: 'Adicionar',
  addItem: '01',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['text'],
    },
  ],
  properties: {
    text: {
      title: 'Escreva um rótulo',
      widget: 'textarea',
    },
  },
  required: ['text'],
});
