import {SidebarPortal} from "@plone/volto/components"
import ViewTechnologicalShowcase from "./View";
import DataUtfprTechnologicalShowcase from "./Data";

const EditTechnologicalShowCase = (props)=>{
    return(<>
        <SidebarPortal selected={props.selected}>
            <DataUtfprTechnologicalShowcase {...props}></DataUtfprTechnologicalShowcase>
        </SidebarPortal>
        <ViewTechnologicalShowcase {...props}></ViewTechnologicalShowcase>
    </>)
}

export default EditTechnologicalShowCase;