const yearSchema = (props)=>(
    {
        title: "Ano",
        addMessage: "Adicionar",
        fieldsets: [
            {
                id: "default",
                title: "Default",
                fields: ["year","indicativeNumberValue", "indicativeNumberTitle", "indicativeNumberDesc", "statisticValue", "statisticTitle", "statisticDesc", "indicativeMoneyValue", "indicativeMoneyTitle", "indicativeMoneyDesc", "indicativePercentageValue", "indicativePercentageTitle", "indicativePercentageDesc", "metricsDesc", "metrics"]
            }
        ],
        properties: {
            year: {
                title: "Ano",
                widget: "number"
            },
            indicativeNumberValue: {
                title: "Indicativo Número (Valor)",
                widget: "text"
            },
            indicativeNumberTitle: {
                title: "Indicativo Número (Título)",
                widget: "text"
            },
            indicativeNumberDesc: {
                title: "Indicativo Número (Descrição)",
                widget: "text"
            },
            statisticValue: {
                title: "Estatística (Valor)",
                widget: "text"

            },
            statisticTitle: {
                title: "Estatística (Title)",
                widget: "text"
            },
            statisticDesc: {
                title: "Estatística (Descrição)",
                widget: "text"
            },
            indicativeMoneyValue: {
                title: "Indicativo Monetário (Valor)",
                widget: "text"
            },
            indicativeMoneyTitle: {
                title: "Indicativo Monetário (Título)",
                widget: "text"
            },
            indicativeMoneyDesc: {
                title: "Indicativo Monetário (Descrição)",
                widget: "text"
            },
            indicativePercentageValue: {
                title: "Indicativo Percentual (Valor)",
                widget: "text"
            },
            indicativePercentageTitle: {
                title: "Indicativo Percentual (Título)",
                widget: "text"
            },
            indicativePercentageDesc: {
                title: "Indicativo Percentual (Descrição)",
                widget: "text"
            },
            metricsDesc: {
                title: "Métricas Descrição",
                widget: "text"
            },
            metrics:{
                title: "Métricas",
                widget: "object_list",
                schema: metricsSchema
            }
        },
        required: ["year","indicativeNumberValue", "indicativeNumberTitle", "indicativeNumberDesc", "statisticValue", "statisticTitle", "statisticDesc", "indicativeMoneyValue", "indicativeMoneyTitle", "indicativeMoneyDesc", "indicativePercentageValue", "indicativePercentageTitle", "indicativePercentageDesc", "metricsDesc", "metrics"]
    }
)

const metricsSchema = (props)=>(
    {
        title: "Métricas",
        addMessage: "Adicionar",
        fieldsets: [
            {
                id: "default",
                title: "Default",
                fields: ["year", "number"]
            }
        ],
        properties: {
            year: {
                title: "Ano",
                widget: "text"
            },
            number: {
                title: "Número",
                widget: "text",

            },
        },
        required: ["year","number"]
    }
)    

export const schemaUtfprTechnologicalShowcase = ({data, intl})=>{
    return{
        title: "UTFPR Showcase",
        fieldsets: [
            {
                id: "default",
                title: "Default",
                fields: ["number"]
            }
        ],
        properties: {
            number:{
                title: "Adicionar ano",
                widget: "object_list",
                schema: yearSchema
            }
        },
        required: []
    }
}