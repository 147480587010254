import ImageView from './ImageView.jsx';
import ImageEdit from './ImageEdit.jsx';
import icon from '../../assets/icons/imageCard.svg';

export default {
  id: 'imageCard',
  title: 'Image Card',
  group: 'media',
  view: ImageView,
  edit: ImageEdit,
  icon: icon,
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
};
