import React from 'react';

export const highlightCities = (text) => {
  if (typeof text !== 'string') return text;

  const cities = [
    'apucarana', 'campo mourão', 'cornélio procópio', 'curitiba', 'dois vizinhos',
    'francisco beltrão', 'guarapuava', 'londrina', 'medianeira', 'pato branco',
    'ponta grossa', 'santa helena', 'toledo'
  ];

  const regex = new RegExp(`\\b(${cities.join('|')}|Sei|SEI)\\b`, 'gi');
  const urlRegex = /https?:\/\/[^\s]+|www\.[^\s]+/gi;
  const emailRegex = /\S+@\S+\.\S+/gi;

  return text.split(regex).map((part, index, array) => {
    if (regex.test(part)) {
      if (cities.includes(part.toLowerCase()) || part === 'SEI' || part === 'Sei') {
        const prevChar = index > 0 ? array[index - 1].slice(-1) : '';
        const nextChar = index < array.length - 1 ? array[index + 1][0] : '';

        const before = prevChar && !/[-]/.test(prevChar) ? ' ' : '';
        const after = nextChar && !/[!\-]/.test(nextChar) ? ' ' : '';

        return (
          <React.Fragment key={index}>
            {before}<span translate="no">{part}</span>{after}
          </React.Fragment>
        );
      }
    }

    return part.split('\n').map((line, i, lines) => {
      const isLink = urlRegex.test(line) || emailRegex.test(line);
      const isLastLine = i === lines.length - 1;
    
      return (
        <React.Fragment key={`${index}-${i}`}>
          {isLink ? (
            <a href={line.match(urlRegex) ? line : `mailto:${line}`} rel="noopener noreferrer">
              <span>{line}</span>
            </a>
          ) : (
            <span>{line}</span>
          )}
          {!isLastLine && <br />} 
        </React.Fragment>
      );
    });
  });
};