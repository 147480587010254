import {BlockDataForm} from '@plone/volto/components';
import { schemaUtfprTechnologicalShowcase } from './Schema';

const DataUtfprTechnologicalShowcase = (props)=>{
    const {block, onChangeBlock, data, intl} = props;
    let schema = schemaUtfprTechnologicalShowcase({data, intl});

    return(<BlockDataForm
        schema={schema}
        
        onChangeField={(id, value)=>{
            onChangeBlock(props.block, {...data, [id]: value})
        }}
        onChangeBlock={onChangeBlock}
        formData={data}
        >

    </BlockDataForm>)
}

export default DataUtfprTechnologicalShowcase;